// src/components/UI/Dialogs/ModelDialog.js

import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, TextField, DialogActions, Button } from '@mui/material';

function ModelDialog({ open, onClose, onSave }) {
    const [modelName, setModelName] = useState('');
    const [error, setError] = useState('');
    const [saving, setSaving] = useState(false);

    // Reset state when dialog opens/closes
    useEffect(() => {
        if (!open) {
            setModelName('');
            setError('');
            setSaving(false);
        }
    }, [open]);

    const handleSave = async () => {
        if (!modelName.trim()) {
            setError('Please enter a model name');
            return;
        }

        setSaving(true);
        setError('');

        try {
            await onSave(modelName.trim());
            setModelName('');
            onClose();
        } catch (err) {
            console.error('Error saving model:', err);
            setError('Failed to save model. Please try again.');
        } finally {
            setSaving(false);
        }
    };

    const handleClose = () => {
        setModelName('');
        setError('');
        onClose();
    };

    return (
        <Dialog 
            open={open} 
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>Save Model</DialogTitle>
            <div style={{ padding: '0 24px' }}>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Model Name"
                    type="text"
                    fullWidth
                    value={modelName}
                    onChange={(e) => {
                        setModelName(e.target.value);
                        setError('');
                    }}
                    error={!!error}
                    helperText={error}
                    disabled={saving}
                />
            </div>
            <DialogActions>
                <Button 
                    onClick={handleClose} 
                    color="primary"
                    disabled={saving}
                >
                    Cancel
                </Button>
                <Button 
                    onClick={handleSave} 
                    color="primary"
                    disabled={saving || !modelName.trim()}
                >
                    {saving ? 'Saving...' : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ModelDialog;