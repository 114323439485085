import { hUnit, pUnit, dUnit, pinHeight, parts_material } from '../../globals';
import { state, box, cyl, logo } from '../../globals';
import { Sensor } from './sensor';
import React  from 'react';

export function FlatPins(props) {
    return (
        <>

            {/* ///pins on top */}
            {!props.flat ? (
                props.rows.map(function ([i, j, id]) {
                    return (
                        (props.norow.includes(i)) ? (
                            null
                        ) : (
                            <>
                            <mesh material={parts_material.clone()} material-color={props.col} key={id} name={id} geometry={cyl} scale={[dUnit / 2, pinHeight, dUnit / 2]} position={[pUnit / 2 + j * pUnit, props.hT * hUnit + 0.8, pUnit / 2 + i * pUnit]} />
                            {/* <Sensor key={"dn" + id} name={'btn' + id} inmodel={props.inmodel} li={i} lj={j}  position={[pUnit / 2 + i * pUnit, 1.2, pUnit / 2 + j * pUnit]} /> */}
                            <Sensor name={'tp_'+props.name + id} key={id+1000} inmodel={props.inmodel} li={i} lj={j}  position={[pUnit / 2 + j * pUnit, props.hT * hUnit + 0.8, pUnit / 2 + i * pUnit]} />

                            </>
                          )
                    )
                })
            ) : null
            }


            {/* ///pins on botttom */}
            {
                props.rows.map(function ([i, j, id]) {
                    return (
                        (props.norowb.includes(i)) ? (
                            null
                        ) : (
                            <>
                            <mesh material={parts_material.clone()} material-color={props.col} key={"bt" + id} name={"bt" + id} geometry={cyl} scale={[dUnit / 2+0.1, pinHeight+0.1, dUnit / 2 +0.1]} position={[pUnit / 2 + j * pUnit, 0.8, pUnit / 2 + i * pUnit]} />
                            <Sensor name={'bt-'+props.name + id} key={id+2000} inmodel={props.inmodel} li={i} lj={j}  position={[pUnit / 2 + j * pUnit, 0.8, pUnit / 2 + i * pUnit]} />
                            </>
                        )
                    )
                })

            }

            {/* {!props.flat ? (

                props.rows.map(function ([i, j, id]) {
                    return (
                        (props.norow.includes(i)) ? (
                            null
                        ) : (
                            <>
                            <mesh material={parts_material.clone()} material-color={props.col} key={"txt" + id} name={"tx" + id} geometry={logo} position={[pUnit / 2 + j * pUnit, props.hT * hUnit + 1, pUnit / 2 + i * pUnit]} />
                            <Sensor name={props.name + id} key={id+3000} inmodel={props.inmodel} li={i} lj={j}  position={[pUnit / 2 + j * pUnit, props.hT * hUnit + 1, pUnit / 2 + i * pUnit]} />
                            </>
                        )
                    )
                })
            ) : null
            } */}



                        {/* Pin key */}
                        <mesh material={parts_material.clone()} material-color={'yellow'} key={"txt" + 777} name={"tx" + 777} geometry={logo} position={[pUnit / 2 + 0 * pUnit, props.hT * hUnit + 1+0.1, pUnit / 2 + 0* pUnit]} />
 


        </>
    )
}
