import * as THREE from 'three'
import { useRef } from 'react'
import { Geometry, Base} from '@react-three/csg'

import {  useSnapshot } from 'valtio'
import * as React from 'react';
import { state, cyl, m, p, m2, p2, m_dir,p_dir, m2_dir, p2_dir } from '../../globals';

import {  dUnit, pinHeight, parts_material } from '../../globals';

export function Sensor(props) {
    const csg = useRef()


    const snap = useSnapshot(state)

    function vis() {


        if (props.inmodel) {
            if (snap.currentInModel === props.name) {
                return true;
            };
            if (snap.currentInModel2 === props.name) {
                return true;
            };

        } else {

            if (snap.current === props.name) {
                return true;
            };
            if (snap.current2 === props.name) {
                return true;
            };

        }

        return false;
    }

    function color() {


        if (props.inmodel) {
            if (snap.currentInModel === props.name) {
                return 'blue';
            };
            if (snap.currentInModel2 === props.name) {
                return 'red';
            };

        } else {

            if (snap.current === props.name) {
                return 'blue';
            };
            if (snap.current2 === props.name) {
                return 'red';
            };

        }
        return props.color;
    }

    const bl = <mesh receiveShadow castShadow
        ref={csg}
        rotation={props.rotation}
        // Click sets the mesh as the new target
        onClick={(e) => {
            
            // console.log("clicked on pos ", props.position);


            // // Access the clicked mesh with e.object
            // console.log('Clicked mesh: ', e.object);

            // // Access the 3D coordinates of the click event with e.point
            // console.log('Click occurred at: ', e.point);
            // console.log('Local position: ', e.object.position);

            // Get world position
            const worldPosition = new THREE.Vector3();
            e.object.getWorldPosition(worldPosition);

            const wp = e.object.position.clone();
            wp.applyQuaternion(e.object.getWorldQuaternion(new THREE.Quaternion()));
            console.log('World position new: ', wp.x.toFixed(2), wp.y.toFixed(2), wp.z.toFixed(2));
            // const worldDirection = new THREE.Vector3();
            // e.object.getWorldDirection(worldDirection);
            //  console.log('World direction: ', worldDirection);

            const worldDirection = new THREE.Vector3(0, 1, 0);
            worldDirection.applyQuaternion(e.object.getWorldQuaternion(new THREE.Quaternion()));
            worldDirection.normalize();
            console.log('Direction: ', worldDirection);

            // // Get local rotation
            // console.log('Local rotation: ', e.object.rotation);

            // // Get world rotation
            // const worldQuaternion = new THREE.Quaternion();
            // e.object.getWorldQuaternion(worldQuaternion);
            // const worldRotation = new THREE.Euler().setFromQuaternion(worldQuaternion);
            // console.log('World rotation: ', worldRotation);

            

            e.stopPropagation();


            if (props.inmodel) {
                if (snap.stepInModel === 0) {
                    state.currentInModel = props.name;
                    m.copy(worldPosition);
                    m_dir.copy(worldDirection);

                    console.log('M:',m.x.toFixed(2),m.y.toFixed(2),m.z.toFixed(2));
                    console.log('M_DIR:',m_dir.x.toFixed(2),m_dir.y.toFixed(2),m_dir.z.toFixed(2));
                   
                   // modelRotation.y = worldRotation.y;
                    state.stepInModel = 1;
                } else {
                    state.currentInModel2 = props.name;
                    m2.copy(worldPosition);
                    m2_dir.copy(worldDirection);

                    console.log('M2:',m2.x.toFixed(2),m2.y.toFixed(2),m2.z.toFixed(2));
                   // modelRotation.y = worldRotation.y;
                    state.stepInModel = 0;
                }

            } else {

                if (snap.step === 0) {
                    state.current = props.name;

                    p.copy(worldPosition);
                    p_dir.copy(worldDirection);

                    
                    console.log('P:',p.x.toFixed(2),p.y.toFixed(2),p.z.toFixed(2));
                    console.log('P_DIR:',p_dir.x.toFixed(2),p_dir.y.toFixed(2),p_dir.z.toFixed(2));
                   
                    state.step = 1;
                } else {
                    state.current2 = props.name;

                    p2.copy(worldPosition); 
                    p2_dir.copy(worldDirection);

                    console.log('P2:',p2.x.toFixed(2),p2.y.toFixed(2),p2.z.toFixed(2));
                    state.step = 0;
                }
            }

        }}


        onPointerMissed={(e) => {
            // e.type === 'click' && (state.current = null)}
            if (e.type === 'click') {
                //  console.log('m.x m.y m.z=', m.x, m.y, m.z);
                if (props.inmodel) {
                    if (snap.stepInModel === 0) {
                        state.currentInModel = null;
                        m.x = null
                        m.y = null;
                        m.z = null;
                        m_dir.x = null;
                        m_dir.y = null;
                        m_dir.z = null;
                    }
                    else {
                        state.currentInModel2 = null;
                        m2.x = null;
                        m2.y = null;
                        m2.z = null;
                    }
                } else {
                    if (snap.step === 0) {
                        state.current = null;
                        p.x = null;
                        p.y = null;
                        p.z = null;

                    }
                    else {
                        state.current2 = null;
                        p2.x = null;
                        p2.y = null;
                        p2.z = null;
                    }
                }
            }
        }
        }



        name={props.name}

        material={parts_material.clone()}


        material-color={color()}



        visible={vis()}



        {...props}

        dispose={null}
    >

        {/* <Geometry  computeVertexNormals> */}
        < Geometry ref={csg} computeVertexNormals  >

            <Base name="cylp" geometry={cyl} scale={[0.1, 0.1, 0.1]} position={[0, 0, 0]} />
            <Base name="cylp2" geometry={cyl} scale={[dUnit / 2 + 0.1+0.1, pinHeight + 1.0, dUnit / 2 + 0.1+0.1]} position={[0, 0, 0]} />
            {/* position={[3.9 + props.lj * 8, 9.6 + 1.2+20, 3.9 + props.li * 8]}  */}

        </Geometry >

    </mesh >


    return bl;
}
