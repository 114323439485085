import * as React from 'react';
import { useState, useEffect } from 'react'
import { DataContext } from '../../contexts/DataContext';
import { LoadParts } from '../../serverCalls/SaveParts';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';


const HoverImage = styled('div')({
    display: 'none',
    position: 'absolute',
    zIndex: 1500,
    width: '200px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: '1px solid black',

    '& img': {
        width: '100%',
        height: 'auto',
    }
});

const SmallImage = styled('img')({
    width: '50px',
    height: 'auto',
});

const ImageContainer = styled('div')({
    position: 'relative',
    display: 'inline-block',
    '&:hover': {
        ['${HoverImage}']: {
            display: 'block',
        },
    },
});


const columns = [

    { field: 'id', headerName: 'ID', width: 30, sx: { fontSize: 14, fontFamily: 'Arial' }, },
    { field: 'part_type', headerName: 'Part type', width: 90, sx: { fontSize: 14, fontFamily: 'Arial' }, },
    { field: 'part_length', headerName: 'Length', width: 70, sx: { fontSize: 14, fontFamily: 'Arial' }, },
    { field: 'part_width', headerName: 'Width', width: 70, sx: { fontSize: 12, fontFamily: 'Arial' }, },
    {
        field: 'part_image_txt',
        headerName: 'Image',
        sortable: false,
        width: 70,
        renderCell: (params) => {
            const imageBinaryData = params.value;

            if (imageBinaryData) {


                //return <Avatar src={`data:image/png;base64,${imageBinaryData}`} style={{ transform: `scale(${1})` }}/>;
                return (
                    <ImageContainer>
                        <SmallImage src={`data:image/png;base64,${imageBinaryData}`} alt="Thumbnail" />
                        <HoverImage>
                            <img src={`data:image/png;base64,${imageBinaryData}`} alt="Large View" />
                        </HoverImage>
                    </ImageContainer>
                );


            }

        },
    },
]

export function PartsTable({ onRowClick }) {
    const handleRowClick = (part_length, part_width, part_colour, part_type, is_transparent) => {
        if (onRowClick) {
            onRowClick(part_length, part_width, part_colour, part_type, is_transparent);
        }
    };

    const { data, setData } = React.useContext(DataContext);


    //Local state to prevent direct mutation from data context.

    const [parts, setParts] = useState(data);

    useEffect(() => {
        async function GetParts() {

            const parts = await LoadParts();
            setData(parts);
            setParts(parts);
        }

        GetParts();

    }, [setData]);


    useEffect(() => {
        // This effect runs whenever the 'data' prop changes
        setParts(data); // Update the local 'parts' state with the new data
    }, [data]);


    data.forEach(element => {
        element.id = element.part_id;
    });

    const minHeight = 200;
    const maxHeight = 400;

    const renderAuth = () => {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxHeight : 500
                }}
            >

                <DataGrid
                    rows={data}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 8 },
                        },
                    }}
                    pageSizeOptions={[5, 10]}
                    isRowSelectable={(row) =>
                        false
                    }
                    onRowClick={(rowParams, event) => {
                        console.log("Row was clicked. Event details: ", rowParams);
                        handleRowClick(rowParams.row.part_length, rowParams.row.part_width, rowParams.row.part_color, rowParams.row.part_type, rowParams.row.is_transparent)
                    }}
                    sx={{ typography: { fontFamily: 'Arial', fontSize: 14 } }}

                />
            </div>
        );
    }

    return (
        <div style={{
            padding: '2px',
            backgroundColor: '#f7f7f7',
            borderRadius: '10px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            height: '100%'
        }}>
            {renderAuth()}
        </div>
    );

}
export default PartsTable;