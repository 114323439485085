// src/DataContext.js

import React, { createContext, useState } from 'react';

export const DataContext = createContext();

export const DataProvider = ({ children }) => {  // Renamed to DataProvider
    const [data, setData] = useState([]);

    // Function to update the data (ensuring immutability)
    const updateData = (newData) => {
        setData(newData);
    };

    return (
        <DataContext.Provider value={{ data, setData }}>
            {children}
        </DataContext.Provider>
    );
};