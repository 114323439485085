// src/services/modelService.js

import { m_dir, p_dir } from '../globals';
import { saveModelToFile, LoadModelFromFile } from '../serverCalls/SaveModels'; // Adjust path if needed

async function saveModel(objects, username, modelName) {

    let s_model = {objects, model_id:0, model_name: modelName};
    // setModel(s_model); //removed setModel from here
    await saveModelToFile(objects, username, modelName);
    return s_model;
}

async function loadModel(username, modelId) {
     var obj2;

    obj2 = await LoadModelFromFile(username, modelId);
    let parts = [];

    for (let key in obj2) {
        let element = obj2[key];

        let newPin;
        let id = 0;

        const newPartInModel =
        {
            id: element.id, part_type: element.part_type, name: element.name, length: element.length, width: element.width,
            posX: element.posX, color: element.color,
            quaternion: element.quaternion,
            m1: element.m1, m2: element.m2, 
            p1: element.p1, p2: element.p2,
            m_dir: element.m_dir, p_dir: element.p_dir,
            posY: element.posY, posZ: element.posZ, rotX: element.rotX, rotY: element.rotY, rotZ: element.rotZ, rows: []
        };
        for (let gi = 0; gi < element.length; gi++) {
            for (let gj = 0; gj < element.width; gj++) {

                newPin = {
                    i: gi,
                    j: gj,
                    id: id++,
                };

                newPartInModel.rows.push(newPin);
            }
        }
        parts.push(newPartInModel);
    }

    return parts;
}



const modelService = {
    saveModel,
    loadModel,

};

export { modelService };