
import * as THREE from 'three';
import { hUnit, pUnit, dUnit, pinHeight, parts_material } from '../../globals';
import { state, box, cyl, logo } from '../../globals';
import { Geometry, Base, Subtraction, Addition } from '@react-three/csg'
import { FlatPins } from './flatpins';
import React  from 'react';

export function Window(props, trans, color) {

  let shape1 = new THREE.Shape();




  let shape = new THREE.Shape();
  shape.moveTo(0, 0);
  shape.lineTo(pUnit * props.currentPart.width, 0);
  shape.lineTo(pUnit * props.currentPart.width, 3 * props.currentPart.length * hUnit);
  shape.lineTo(0, 3 * props.currentPart.length * hUnit);
  shape.lineTo(0, 0);

  let extrusionSettings = {
    steps: 1,
    depth: pUnit * 2,  // depth to extrude
    bevelEnabled: false,

  };

  let shape2 = new THREE.Shape();
  shape2.moveTo(1, 0);
  shape2.lineTo(pUnit * props.currentPart.width - 1,);
  shape2.lineTo(pUnit * props.currentPart.width - 1, 3 * props.currentPart.length * hUnit - 2);
  shape2.lineTo(1, 3 * props.currentPart.length * hUnit - 2);
  shape2.lineTo(1, 0);

  let extrusionSettings2 = {
    steps: 1,
    depth: pUnit * 2 + 5,  // depth to extrude
    bevelEnabled: false,
  };

  // Create the extruded geometry
  let geometry = new THREE.ExtrudeGeometry(shape, extrusionSettings);
  let geometry2 = new THREE.ExtrudeGeometry(shape2, extrusionSettings2);

  geometry2.translate(0, 3, -3);
 
  let Left =0 ,hMid = pUnit * props.currentPart.width /2 ,Right = pUnit * props.currentPart.width;
  let Bottom =0 ,vMid = 3*hUnit * props.currentPart.length /2 ,Top = 3*hUnit * props.currentPart.length;


  shape = new THREE.Shape();
  shape.moveTo(Left, Bottom);
  shape.lineTo(Right, Bottom);
  shape.lineTo(Right, Top);
  shape.lineTo(Left, Top);
  shape.lineTo(Left, Bottom);


  shape2 = new THREE.Shape();   //letf bottom
  shape2.moveTo(3, 3);
  shape2.lineTo(hMid -1, 3);
  shape2.lineTo(hMid -1, vMid - 1);
  shape2.lineTo(3, vMid -1);
  shape2.lineTo(3, 3);

  shape.holes.push(shape2);

  shape2 = new THREE.Shape();  //right top
  shape2.moveTo(hMid+1, vMid + 1);
  shape2.lineTo(Right  -1, vMid + 1);
  shape2.lineTo(Right  -1, Top  - 1);
  shape2.lineTo(hMid +1,Top  -1);
  shape2.lineTo(hMid +1, vMid + 1);

  shape.holes.push(shape2);

  shape2 = new THREE.Shape();  //rigth bottom
  shape2.moveTo(hMid +1, 3);
  shape2.lineTo(Right  -1, 3);
  shape2.lineTo(Right  -1, vMid - 1);
  shape2.lineTo(hMid +1, vMid -1);
  shape2.lineTo(hMid +1, 3);

  shape.holes.push(shape2);

  shape2 = new THREE.Shape();   //letf top
  shape2.moveTo(3, vMid+1);
  shape2.lineTo(hMid -1, vMid+1);
  shape2.lineTo(hMid -1, Top - 1);
  shape2.lineTo(3, Top -1);
  shape2.lineTo(3, vMid+1);

  shape.holes.push(shape2);


  var extrudeSettings = { amount:5, bevelEnabled: false };
  var gmo = new THREE.ExtrudeGeometry(shape, extrudeSettings);

  let flat = false;
  let hT = 3 * props.currentPart.length;
  let norow = [];
  let norowb = [];
  var rows = [];
  var id = 0;
  //while (++i <= len) rows.push([i,i*2])

  for (let i = 0; i < props.currentPart.width; i++) {
    for (let j = 0; j < 2; j++) {
      rows.push([i, j, id++])
    }
  }


  return (<mesh receiveShadow castShadow

    name={props.name}

    material={parts_material.clone()}
    material-color={color}

    material-transparent={trans}
   
    material-opacity={0.7}

    {...props}

    dispose={null}
  >

    <Geometry ref={props.meshRef}  computeVertexNormals>
      <Base name="roof_incline" geometry={geometry} rotation={[0, -Math.PI / 2, 0]} position={[2 * pUnit, 0, 0]} />
      <Subtraction name="cavity1" geometry={geometry2} rotation={[0, -Math.PI / 2, 0]} position={[2 * pUnit - 1, -2, 0]} />
      <Addition name="win_cross" geometry={gmo} rotation={[0, -Math.PI / 2, 0]} position={[2 , 0, 0]} />
      <FlatPins rows={rows} norow={norow} norowb={norowb} flat={flat} col={color} hT={hT} />
    </Geometry>

  </mesh>)

}
