// src/store/uiStore.js

import { proxy } from 'valtio';

const uiStore = proxy({
    // Dialog states
    isModelDialogOpen: false,
    isModelListDialogOpen: false,
    isCleanModelDialogOpen: false,

    // Model dialog
    openModelDialog: () => {
        if (!uiStore.isModelDialogOpen) {
            uiStore.isModelDialogOpen = true;
            // Close other dialogs
            uiStore.isModelListDialogOpen = false;
            uiStore.isCleanModelDialogOpen = false;
        }
    },
    closeModelDialog: () => {
        uiStore.isModelDialogOpen = false;
    },

    // Model list dialog
    openModelListDialog: () => {
        if (!uiStore.isModelListDialogOpen) {
            uiStore.isModelListDialogOpen = true;
            // Close other dialogs
            uiStore.isModelDialogOpen = false;
            uiStore.isCleanModelDialogOpen = false;
        }
    },
    closeModelListDialog: () => {
        uiStore.isModelListDialogOpen = false;
    },

    // Clean model dialog
    openCleanModelDialog: () => {
        if (!uiStore.isCleanModelDialogOpen) {
            uiStore.isCleanModelDialogOpen = true;
            // Close other dialogs
            uiStore.isModelDialogOpen = false;
            uiStore.isModelListDialogOpen = false;
        }
    },
    closeCleanModelDialog: () => {
        uiStore.isCleanModelDialogOpen = false;
    },
})

export { uiStore }