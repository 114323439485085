import * as React from 'react';
import { useImperativeHandle, useRef } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';

import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

import Grid from '@mui/material/Grid';
import { Canvas, useThree } from '@react-three/fiber'
import { OrbitControls, /*PivotControls, TransformControls, ContactShadows, useGLTF, useCursor */ } from '@react-three/drei'
import { Environment } from '../../../Environment'
import { orbit, pUnit, port } from '../../../globals';
import { CurBlock } from '../../Parts/curblock';
import Checkbox from '@mui/material/Checkbox';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';


import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Select from '@mui/material/Select';

import { useEffect, useState } from 'react';




let imageBase64;




// function View3D(props) {
const View3D = React.forwardRef((props, ref) => {

    const { gl } = useThree();



    // Expose this method to parent component using useImperativeHandle hook
    useImperativeHandle(ref, () => ({
        capture() {
            const screenshot = gl.domElement.toDataURL();
            // var link = document.createElement('a');
            // link.href = screenshot;
            // link.download = 'Download.png';
            // link.click();

            imageBase64 = screenshot.split(",")[1];


        }
    }));

    return (
        <>
            <color attach="background" args={['white']} />

            {/* <CurBlock key='0' name='created' part_type={props.part_type} currentPart = {length={props.ln} width={props.wd}} color={props.color} transparent={props.transparent} inmodel='false' /> */}

            <CurBlock
                key='0'
                name='created'
                currentPart={{ color: props.color, part_type: props.part_type, length: props.ln, width: props.wd }}
                transparent={props.transparent}
                indialog={true}
                inmodel={false}
            />

            <Environment />
            <OrbitControls target={[orbit.x, 0, orbit.z]} />
        </>
    )
    // {/* <gridHelper args={[160, 20, 0xff0000, 'teal']} /> */ }
})



export function FormDialog(props) {

    const sceneRef = useRef();

    const [open, setOpen] = React.useState(false);


    const [part_type, setPartType] = React.useState('brick');
    const partTypes = [
        'brick', 'roof_45', 'roof_45_2', 'roof_30', 'roof_30_2', 'flat',
        'plate', 'door', 'window', 'beam', 'pole', 'wheel', 'angle_beam', 'corner_beam'
    ];

    const [len, setLength] = React.useState('1');
    const [wd, setWidth] = React.useState('1');
    const [colr, setColour] = React.useState('white');

    const [is_transparent, setTransparent] = React.useState(false);

    const [filterText, setFilterText] = useState('');

    const colorOptions = [
        "white", "red", "green", "blue", "yellow", "magenta", "cyan", "olive", "darkgreen", "darkblue"
    ];

    useEffect(() => {
        //set random color on load
        setColour(colorOptions[Math.floor(Math.random() * colorOptions.length)]);
    }, []);

    const handleChangeTransparent = (event) => {
        setTransparent(event.target.checked);
    };

    const handleChangePartType = (event) => {
        setPartType(event.target.value);
    };

    const handleChangeLen = (event) => {
        orbit.x = event.target.value * pUnit / 2;
        setLength(event.target.value);
    };



    const handleChangeWd = (event) => {
        orbit.z = event.target.value * pUnit / 2;
        setWidth(event.target.value);
    };

    const handleChangeColor = (event) => {
        setColour(event.target.value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        //on cancel here
        setOpen(false);
    };

    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        // Small delay to ensure dialog is rendered first
        const timer = setTimeout(() => setIsReady(true), 100);
        setIsReady(false)
        return () => clearTimeout(timer);

    }, []);



    const handleCreatePart = () => {
        sceneRef.current.capture();
        props.OnCreatePart(part_type, len, wd, colr, is_transparent, imageBase64);

        // SendPartImage(imageBase64);
        setOpen(false);
    };

    const filteredPartTypes = partTypes.filter(type =>
        type.toLowerCase().includes(filterText.toLowerCase())
    );

    return (
        <React.Fragment>
            <Button
                variant="outlined"
                size="small"
                onClick={handleClickOpen}
                startIcon={<AddCircleOutlineIcon />}
                sx={{
                    textTransform: 'none',
                    minWidth: '120px',
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)'
                    }
                }}
            >
                Create Part
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    sx: {
                        borderRadius: '8px',
                        minWidth: '500px'
                    }
                }}
                maxWidth="md"
            >
                <DialogTitle sx={{
                    borderBottom: '1px solid #e0e0e0',
                    padding: '16px 24px',
                    backgroundColor: '#fafafa'
                }}>
                    <Typography variant="h6" component="div" sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        color: 'text.primary',
                        fontWeight: 500
                    }}>
                        <AddIcon sx={{ fontSize: 20 }} />
                        Create New Part
                    </Typography>
                </DialogTitle>
                <DialogContent sx={{
                    padding: '24px',
                    backgroundColor: '#ffffff'
                }}>
                    {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We
            will send updates occasionally.
          </DialogContentText> */}
                    <Grid container spacing={3} sx={{ mb: 2 }}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1 }}>
                                Part Properties
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <FormControl fullWidth>
                                <TextField
                                    id="id_length"
                                    label="Length"
                                    variant="outlined"
                                    value={len}
                                    onChange={handleChangeLen}
                                    size="small"
                                    type="number"
                                    inputProps={{ min: 1, step: 1 }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <FormControl fullWidth>
                                <TextField
                                    id="id_width"
                                    label="Width"
                                    variant="outlined"
                                    value={wd}
                                    onChange={handleChangeWd}
                                    size="small"
                                    type="number"
                                    inputProps={{ min: 1, step: 1 }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <FormControl fullWidth>
                                <InputLabel id="color-label">Color</InputLabel>
                                <Select
                                    labelId="color-label"
                                    id="color-select"
                                    value={colr}
                                    label="Color"
                                    onChange={handleChangeColor}
                                    size="small"
                                    sx={{
                                        backgroundColor: colr,
                                        '& .MuiSelect-select': {
                                            padding: '8px 14px',
                                        }
                                    }}
                                >
                                    <MenuItem value="" disabled>Select a color</MenuItem>
                                    {colorOptions.map((color) => (
                                        <MenuItem key={color} value={color} style={{ backgroundColor: color }}>{color}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </Grid>
                        <Grid item container xs={12} md={12} spacing={2}>
                            <Grid item xs={12} md={5}>
                                <FormControl fullWidth>
                                    <Typography variant="subtitle1" color="text.primary" sx={{ mb: 1 }}>
                                        Part Type: <Typography component="span" color="primary" sx={{ textTransform: 'capitalize' }}>{part_type}</Typography>
                                    </Typography>
                                    {/* Add Text Filter */}
                                    <TextField
                                        label="Filter Part Type"
                                        variant="outlined"
                                        size="small"
                                        value={filterText}
                                        onChange={(e) => setFilterText(e.target.value)}
                                        sx={{ mb: 1 }}
                                    />
                                    <Box sx={{
                                        display: 'grid',
                                        gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
                                        gap: 2,
                                        maxHeight: '400px',
                                        overflowY: 'auto',
                                        p: 1
                                    }}>
                                        {filteredPartTypes.map((type) => (
                                            <Card
                                                key={type}
                                                sx={{
                                                    cursor: 'pointer',
                                                    border: part_type === type ? '2px solid #1976d2' : '1px solid #ddd',
                                                    height: '120px',
                                                    position: 'relative',
                                                    '&:hover': {
                                                        borderColor: '#1976d2',
                                                        backgroundColor: 'rgba(25, 118, 210, 0.04)'
                                                    }
                                                }}
                                                onClick={() => handleChangePartType({ target: { value: type } })}
                                            >
                                                <CardContent sx={{ height: '100%', p: 1 }}>
                                                    <Box
                                                        component="img"
                                                        src={`/assets/part-types/${type}.png`}
                                                        alt={type}
                                                        sx={{
                                                            height: '80px',
                                                            width: '100%',
                                                            objectFit: 'contain',
                                                            filter: is_transparent ? 'opacity(0.5)' : 'none'
                                                        }}
                                                    />
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            textAlign: 'center',
                                                            textTransform: 'capitalize',
                                                            mt: 1,
                                                            fontSize: '0.75rem',
                                                            lineHeight: 1.2,
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap'
                                                        }}
                                                    >
                                                        {type.replace('_', ' ')}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        ))}
                                    </Box>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={7}>

                                {/* <Typography variant="subtitle1" color="text.primary" sx={{ mb: 1 }}>
                                    Preview
                                </Typography> */}
                                {/*
                                <div style={{ 
                                    width: '100%',
                                    height: '300px',
                                    border: '1px solid rgba(0, 0, 0, 0.12)',
                                    borderRadius: '4px',
                                    overflow: 'hidden'
                                }}> */}

                                <Canvas
                                    gl={{ preserveDrawingBuffer: true, antialias: true }}
                                    shadows
                                    camera={{ position: [-150, 100, 150], fov: 25 }}
                                    style={{
                                        width: "100%",
                                        height: "100%",

                                    }}
                                >
                                    <color attach="background" args={['white']} />
                                    <View3D
                                        ref={sceneRef}
                                        part_type={part_type}
                                        ln={len}
                                        wd={wd}
                                        color={colr}
                                        transparent={is_transparent}
                                    />
                                    <Environment />
                                    <OrbitControls target={[orbit.x, 0, orbit.z]} />
                                </Canvas>

                                {/* </div> */}
                            </Grid>
                        </Grid>


                    </Grid>

                    <Grid container spacing={2} alignItems="center" sx={{ mb: 3 }}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1 }}>
                                Additional Options
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={is_transparent}
                                        onChange={handleChangeTransparent}
                                        size="small"
                                        sx={{
                                            '&.Mui-checked': {
                                                color: 'primary.main',
                                            }
                                        }}
                                    />
                                }
                                label={
                                    <Typography variant="body2" color="text.secondary">
                                        Make part transparent
                                    </Typography>
                                }
                            />
                        </Grid>
                    </Grid>



                </DialogContent>
                <DialogActions sx={{ padding: '16px', gap: '8px' }}>
                    <Button
                        onClick={handleClose}
                        size="small"
                        startIcon={<CloseIcon />}
                        sx={{
                            textTransform: 'none',
                            minWidth: '100px',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.04)'
                            }
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleCreatePart}
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<CheckIcon />}
                        sx={{
                            textTransform: 'none',
                            minWidth: '100px',
                            '&:hover': {
                                backgroundColor: 'primary.dark'
                            }
                        }}
                    >
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default FormDialog;