import React from "react";
import { List, ListItem, ListItemText } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useState, Suspense, useEffect } from 'react'
import { LoadModelsListFromFile } from "../../serverCalls/SaveModels";

export function ModelList({ username, onSelectModel, onClose }) {
  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!username) return;

      setLoading(true);
      setError(null);

      try {
        const vmodels = await LoadModelsListFromFile(username);
        setModels(vmodels || []);
      } catch (err) {
        console.error('Error loading models:', err);
        setError('Failed to load models. Please try again.');
        setModels([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [username]);

  const handleModelClick = async (model) => {
    try {
      await onSelectModel(model);
      onClose();
    } catch (err) {
      console.error('Error selecting model:', err);
      setError('Failed to load model. Please try again.');
    }
  };

  if (loading) {
    return (
      <div style={{ padding: '20px', textAlign: 'center' }}>
        Loading models...
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ padding: '20px', color: 'red', textAlign: 'center' }}>
        {error}
      </div>
    );
  }

  if (!models.length) {
    return (
      <div style={{ padding: '20px', textAlign: 'center' }}>
        No models found.
      </div>
    );
  }

  return (
    <div style={{ overflowY: 'auto', maxHeight: '400px', minWidth: '300px' }}>
      <List>
        {models.map((model) => (
          <ListItem 
            button 
            onClick={() => handleModelClick(model)} 
            key={model.model_id}
            style={{ borderBottom: '1px solid #eee' }}
          >
            <ListItemText 
              primary={model.model_name} 
              secondary={`Created: ${new Date(model.date_of_creation).toLocaleDateString()}`}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
}

export default ModelList;