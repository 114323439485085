// src/components/UI/Dialogs/ModelListDialog.js
import React from 'react';
import { Dialog, DialogTitle } from '@mui/material';
import ModelList from '../ModelList';  // Import the ModelList component

function ModelListDialog({ open, onClose, onSelectModel, username }) {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Select a model</DialogTitle>
            <ModelList username={username} onSelectModel={onSelectModel} onClose={onClose} />
        </Dialog>
    );
}

export default ModelListDialog;