import React, { useRef, useState, useContext } from 'react';
import { useFrame } from '@react-three/fiber';
import { Vector3, Quaternion, MathUtils } from 'three';
import { SelectionContext } from '../../contexts/SelectionContext'; // Import the context

import { Brick } from './brick';
import { Plate } from './plate';
import { Flat } from './flat'
import { Roof45 } from './roof45';
import { Roof45_2 } from './roof45_2';
import { Roof30 } from './roof30';
import { Roof30_2 } from './roof30_2';
import { Window } from './window';
import { Door } from './door';
import { Beam } from './beam';
import { AngleBeam } from './angle_beam';
import { CornerBeam } from './corner_beam';
import { Pole } from './pole';
import { Wheel } from './wheel';



export function CurBlock(props) {
  const { currentb, setCurrentb } = useContext(SelectionContext); // Access global state

  const handleClick = (e) => {
    if (props.inmodel) {
      setCurrentb(props.currentPart.name); // Update the state
    }
  };

  const handlePointerMissed = (e) => e.type === 'click' && (setCurrentb(null))


  const [angle1, setAngle] = useState(0);

  // const groupRef = useRef()
  const meshRef = useRef()

  function trans() {
    if (props.inmodel) {
      if (currentb === props.currentPart.name) {
        return true;
      }
      else {
        return false;
      }
    }
    else
      if (props.indialog) {
        return props.transparent;
      }
      else {
        return true;
      }
  }


  function color() {
    if (props.inmodel) {
      if (currentb === props.currentPart.name) {
        return 'green';
      }
      else {
        return props.currentPart.color;
      }
    }
    else
      return props.currentPart.color;
  }

  useFrame((state) => {
    
    if (!props.inmodel) {

      return
    }

    if (
      meshRef.current &&
      props.currentPart.p1 && props.currentPart.p_dir &&
      props.currentPart.m1 && props.currentPart.m_dir 
    ) {
      const object = meshRef.current;

      // if (props.currentPart.id == 0) {
      //   const angle = MathUtils.degToRad(0);

      //   const m_dir1 = new Vector3(1, 0, 1).normalize();

      //   const secondaryRotation = new Quaternion().setFromAxisAngle(m_dir1, angle)
      //   object.quaternion.copy(secondaryRotation)
      //   return;
      // }


      // Clone all vectors to avoid modifying props
      const p1 = new Vector3(props.currentPart.p1.x, props.currentPart.p1.y, props.currentPart.p1.z)
      const p2 = new Vector3(props.currentPart.p2.x, props.currentPart.p2.y, props.currentPart.p2.z)
      const m1 = new Vector3(props.currentPart.m1.x, props.currentPart.m1.y, props.currentPart.m1.z)
      const m2 = new Vector3(props.currentPart.m2.x, props.currentPart.m2.y, props.currentPart.m2.z)
      const p_dir = new Vector3(props.currentPart.p_dir.x, props.currentPart.p_dir.y, props.currentPart.p_dir.z)
      const m_dir = new Vector3(props.currentPart.m_dir.x, props.currentPart.m_dir.y, props.currentPart.m_dir.z)


      // 1. First align direction vectors (p_dir with m_dir)
      const initialRotation = new Quaternion().setFromUnitVectors(p_dir, m_dir)

      // 2. Get and rotate object vector
      const objectVector = new Vector3().subVectors(p2, p1).normalize()
      const targetVector = new Vector3().subVectors(m2, m1).normalize()
      const rotatedObjectVector = objectVector.clone().applyQuaternion(initialRotation)

      // 3. Calculate secondary rotation around m_dir
      const angle = rotatedObjectVector.angleTo(targetVector)
      const cross = rotatedObjectVector.cross(targetVector)
      let signedAngle = cross.dot(m_dir) < 0 ? -angle : angle

      // signedAngle = angle1 + 3/180*Math.PI;
      setAngle(signedAngle); // Update state for next frame


      const secondaryRotation = new Quaternion().setFromAxisAngle(m_dir, signedAngle)
      const finalRotation = secondaryRotation.multiply(initialRotation)

      // 4. Calculate final position
      const rotatedP1 = p1.clone().applyQuaternion(finalRotation)
      const translation = new Vector3().subVectors(m1, rotatedP1)

      // Apply transformations
      object.quaternion.copy(finalRotation)
      object.position.copy(translation)
    }
  });




  //ref:meshRef  required to make work const object = meshRef.current; on useFrame this file

  switch (props.currentPart.part_type) {
    case 'brick':
      return Brick({ ...props, ref: meshRef, onClick: handleClick, onPointerMissed: handlePointerMissed }, trans(), color());
    case 'window':
      return Window({ ...props, ref: meshRef, onClick: handleClick, onPointerMissed: handlePointerMissed }, trans(), color());
    case 'door':
      return Door({ ...props, ref: meshRef, onClick: handleClick, onPointerMissed: handlePointerMissed }, trans(), color());
    case 'flat':
      return Flat({ ...props, ref: meshRef, onClick: handleClick, onPointerMissed: handlePointerMissed }, trans(), color());
    case 'plate':
      return Plate({ ...props, ref: meshRef, onClick: handleClick, onPointerMissed: handlePointerMissed }, trans(), color());
    case 'roof_45':
      return Roof45({ ...props, ref: meshRef, onClick: handleClick, onPointerMissed: handlePointerMissed }, trans(), color());
    case 'roof_45_2':
      return Roof45_2({ ...props, ref: meshRef, onClick: handleClick, onPointerMissed: handlePointerMissed }, trans(), color());
    case 'roof_30':
      return Roof30({ ...props, ref: meshRef, onClick: handleClick, onPointerMissed: handlePointerMissed }, trans(), color());
    case 'roof_30_2':
      return Roof30_2({ ...props, ref: meshRef, onClick: handleClick, onPointerMissed: handlePointerMissed }, trans(), color());
    case 'beam':
      return Beam({ ...props, ref: meshRef, onClick: handleClick, onPointerMissed: handlePointerMissed }, trans(), color());
    case 'angle_beam':
      return AngleBeam({ ...props, ref: meshRef, onClick: handleClick, onPointerMissed: handlePointerMissed }, trans(), color());
    case 'corner_beam':
      return CornerBeam({ ...props, ref: meshRef, onClick: handleClick, onPointerMissed: handlePointerMissed }, trans(), color());
    case 'pole':
      return Pole({ ...props, ref: meshRef, onClick: handleClick, onPointerMissed: handlePointerMissed }, trans(), color());
    case 'wheel':
      return Wheel({ ...props, ref: meshRef, onClick: handleClick, onPointerMissed: handlePointerMissed }, trans(), color());
    default:
      return Brick({ ...props, ref: meshRef, onClick: handleClick, onPointerMissed: handlePointerMissed }, trans(), color());
  }

}