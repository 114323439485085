
import * as THREE from 'three';
import { hUnit, pUnit, parts_material } from '../../globals';
import { state } from '../../globals';
import { Geometry, Base, Subtraction } from '@react-three/csg'
import { FlatPins } from './flatpins';
import React  from 'react';

export function Brick(props,trans,color) {


    let shape = new THREE.Shape();
    let shape2 = new THREE.Shape();
  
  
    let extrusionSettings = {
      steps: 1,
      depth: pUnit * props.currentPart.length-0.05,  // depth to extrude
      bevelEnabled: false,
  
    };
  
    let extrusionSettings2 = {
      steps: 1,
      depth: pUnit * props.currentPart.length - 2,  // depth to extrude
      bevelEnabled: false,
    };

    shape.lineTo(0.05, 0);
    shape.lineTo(pUnit * props.currentPart.width-0.05, 0);
    shape.lineTo(pUnit * props.currentPart.width-0.05, 3 * hUnit-0.05);
    shape.lineTo(0.05, 3 * hUnit-0.05);
    shape.lineTo(0.05, 0);

    shape2.lineTo(1, 0);
    shape2.lineTo(pUnit * props.currentPart.width - 2, 0);
    shape2.lineTo(pUnit * props.currentPart.width - 2, 3 * hUnit - 1);
    shape2.lineTo(0, 3 * hUnit - 1);
    shape2.lineTo(1, 0);

      // Create the extruded geometry
  let geometry = new THREE.ExtrudeGeometry(shape, extrusionSettings);
  let geometry2 = new THREE.ExtrudeGeometry(shape2, extrusionSettings2);


  let flat = false;
  let hT = 3;
  let norow = [];
  let norowb = [];
  var rows = [];
  var id = 0;

  for (let i = 0; i < props.currentPart.width; i++) {
    for (let j = 0; j < props.currentPart.length; j++) {
      rows.push([i, j, id++])
    }
  }

  return (<mesh  receiveShadow castShadow
    name={props.currentPart.name}

    material={parts_material.clone()}
    roughness={0.3}
    metalness={0.8}
    material-color={color}

    material-transparent= {trans}  //{props.CurrentPartbTrans}
    material-opacity = {0.7} //{props.CurrentPartbTrans ? 0.7 : 1}

    {...props}

    dispose={null}
  >

    <Geometry computeVertexNormals>
      <Base name="roof_incline" geometry={geometry} rotation={[0, -Math.PI / 2, 0]} position={[pUnit * props.currentPart.length, 0, 0]} />
      <Subtraction name="cavity1" geometry={geometry2} rotation={[0, -Math.PI / 2, 0]} position={[pUnit * props.currentPart.length - 1, -2, 0]} />

      <FlatPins rows={rows} norow={norow} norowb={norowb} flat={flat} col={color} hT={hT} name={props.currentPart.name} inmodel={props.inmodel} />
    </Geometry>

  </mesh>)
}

