
import * as THREE from 'three';
import { dUnit, hUnit, pUnit, parts_material } from '../../globals';
import { Geometry, Base, Subtraction } from '@react-three/csg'
import { React, useRef } from 'react';
import { Sensor } from './sensor';

export function Beam(props, trans1, color1 ) {

  let { currentPart, rotation, inmodel, trans, color, name } = props;
  let u_name =currentPart.name

  if (trans1 !== undefined && color1 !== undefined) {
    trans = trans1;
    color = color1;
  }
  if (name !== undefined) {
    u_name = name;
  }

  const length = currentPart.length * pUnit;
  const width = currentPart.width * dUnit;
  const height = pUnit;
  const holeCount = currentPart.length;
  const holeRadius = dUnit / 2;
  const spacing = pUnit;

  // Create beam geometry with holes
  const createBeamGeometry = () => {
    const shape = new THREE.Shape();


    const cornerRadius = pUnit / 2; // Adjust this value to change corner roundness

    // Create rounded rectangle
    const halfLength = length / 2;
    const halfHeight = height / 2;

    shape.moveTo(-halfLength + cornerRadius, -halfHeight);
    // Bottom edge
    shape.lineTo(halfLength - cornerRadius, -halfHeight);
    // Bottom-right corner
    shape.quadraticCurveTo(halfLength, -halfHeight, halfLength, -halfHeight + cornerRadius);
    // Right edge
    shape.lineTo(halfLength, halfHeight - cornerRadius);
    // Top-right corner
    shape.quadraticCurveTo(halfLength, halfHeight, halfLength - cornerRadius, halfHeight);
    // Top edge
    shape.lineTo(-halfLength + cornerRadius, halfHeight);
    // Top-left corner
    shape.quadraticCurveTo(-halfLength, halfHeight, -halfLength, halfHeight - cornerRadius);
    // Left edge
    shape.lineTo(-halfLength, -halfHeight + cornerRadius);
    // Bottom-left corner
    shape.quadraticCurveTo(-halfLength, -halfHeight, -halfLength + cornerRadius, -halfHeight);

    // Add holes


    for (let i = 1; i <= holeCount; i++) {
      const hole = new THREE.Path();
      const x = -length / 2 - pUnit / 2 + (spacing * i);
      hole.absarc(x, 0, holeRadius, 0, Math.PI * 2, true);
      shape.holes.push(hole);
    }

    // Extrude the shape
    const extrudeSettings = {
      depth: width,
      bevelEnabled: false
    };

    return new THREE.ExtrudeGeometry(shape, extrudeSettings);
  };


  // Calculate sensor positions (same as holes)
  const getSensorPositions = () => {
    const positions = [];

    for (let i = 1; i <= holeCount; i++) {
      const x = -length / 2 - pUnit / 2 + (spacing * i);
      positions.push([x, 0, width / 2]); // Position at center of beam thickness

    }
    return positions;
  };

  const sensorPositions = getSensorPositions();

  const geometry = createBeamGeometry();

  return (

      <mesh receiveShadow castShadow

        name={props.currentPart.name}

        material={parts_material.clone()}
        material-color={color}

        material-transparent={trans}  //{props.CurrentPartbTrans}
        material-opacity={0.7} //{props.CurrentPartbTrans ? 0.7 : 1}

        {...props}

        dispose={null}
      >

        <Geometry computeVertexNormals  rotation={rotation}>
          <Base name="beam" geometry={geometry} position={[0, 0, 0]} />
          {/* <Subtraction name="cavity1" geometry={geometry2} rotation={[0, -Math.PI / 2, 0]} position={[pUnit * props.currentPart.length - 1, -2, 0]} />
  
        <FlatPins rows={rows} norow={norow} norowb={norowb} flat={flat} col={color} hT={hT} name={props.currentPart.name} inmodel={props.inmodel} /> */}
        
              {/* Sensors */}
            {sensorPositions.map((position, index) => (
              <Sensor
                key={5000+index}
                position={position}
                rotation={[Math.PI / 2, 0, 0]} // Rotates 90 degrees around the x-axis
                name={'bm_'+currentPart.name + index}
                inmodel={inmodel}
              />
            ))}
        
        </Geometry>

      </mesh>)

}
