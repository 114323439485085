
import * as THREE from 'three';
import { dUnit, hUnit, pUnit, parts_material } from '../../globals';
import { Geometry, Base, Subtraction } from '@react-three/csg'
import { React, useRef } from 'react';
import { Sensor } from './sensor';


// Component for the main pole
const PoleTube = ({ poleHeight }) => {
  return (
    <mesh>
      <cylinderGeometry args={[dUnit / 2, dUnit / 2, poleHeight, 32]} />
      <meshStandardMaterial color="#8b4513" />
    </mesh>
  );
};


export function Pole(props, trans, color) {


  const poleHeight = props.currentPart.length * dUnit;
  const poleRadius = dUnit / 2;
  const segments = props.currentPart.length;

  // Create an array of sensors
  const sensors = [];
  for (let i = 0; i < segments; i++) {
    const yPosition = (poleHeight / 2) - (dUnit / 2) - (i * dUnit);
    sensors.push(
      <Sensor
        key={'po_' + props.currentPart.name+i}
        name={'pok_' + props.currentPart.name + i}
        position={[0, yPosition, 0]}
        dUnit={dUnit}
        pUnit={pUnit}
        inmodel={props.inmodel}
      />
    );
  }


  const geometry = new THREE.CylinderGeometry(poleRadius, poleRadius, poleHeight, 32);

  return (

    <mesh receiveShadow castShadow

      name={props.currentPart.name}

      material={parts_material.clone()}
      material-color={color}

      material-transparent={trans}  //{props.CurrentPartbTrans}
      material-opacity={0.7} //{props.CurrentPartbTrans ? 0.7 : 1}

      {...props}

      dispose={null}
    >

      <Geometry computeVertexNormals>
        <Base name="beam" geometry={geometry}  />

        {sensors}

      </Geometry>

    </mesh>)

}
