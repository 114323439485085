import { port } from '../globals';


let lurl;

async function loadData(id) {

  if (port !== 'none')
    lurl = "http://localhost:" + port + "/models";
  else
    lurl = "/models";


  let result = await fetch(lurl+'?id='+encodeURIComponent(id.model_id)).then((res) => res.json());
  // let result = await fetch("http://localhost:"+4000+"/models").then((res) => res.json());  
  
  let js;
  js= JSON.parse(result[0].model_body);

  return js;
}
async function loadDataList(username) {

  if (port !== 'none')
    lurl = "http://localhost:" + port + "/models/list";
  else
    lurl = "/models/list";


  let result = await fetch(lurl+'?username='+encodeURIComponent(username)).then((res) => res.json());
  // let result = await fetch("http://localhost:"+4000+"/models").then((res) => res.json());  

  return result;
}


async function sendData(objects, username, modelname) {

  var data={
    username: username,
    modelname: modelname,
    modelsArray: objects
  };


    try {
        
      if (port !== 'none')
      lurl = "http://localhost:"+port+"/models";
   else
       lurl = "/models";


       
      const response = await fetch(lurl   
      , {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data), 
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const json = await response.json();

    } catch (error) {
      console.error('Error:', error);
    }
  }

export function saveModelToFile(objects, username, modelname) {

    sendData(objects, username, modelname);

};

export async function  LoadModelFromFile(username, id){

        let rc =  await loadData(id);  
        return rc;

}

export async function  LoadModelsListFromFile(username){

  let rc =  await loadDataList(username);  

  return rc;

}