import { state, p, p2, orbit } from '../globals';
import { modelStore } from '../store/modelStore';

const handleTableClick = (part_length, part_width, part_colour, part_type, is_transparent) => {
    // console.log('Clicked row data in App:', rowData, 'color', color);

    modelStore.addCurrentPart(part_length, part_width, part_colour, part_type, is_transparent);
    state.current = null;
    state.current2 = null;
    p.x = 0; p.y = 0; p.z = 0;
    p2.x = 0; p2.y = 0; p2.z = 0;
  }

  export { handleTableClick };