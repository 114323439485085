import React, { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, gridHelper } from '@react-three/drei';
import { Environment } from '../../Environment';
import Blocks from '../Parts/Blocks';
//import { useSnapshot } from 'valtio';
//import { modelStore } from '../../store/modelStore';


function MainCanvas() {

    //const { objects } = useSnapshot(modelStore);  // Access objects from the store

    return (
        <Canvas shadows camera={{ position: [-150, 100, 150], fov: 25 }}>
            <Suspense fallback={null}>
                <color attach="background" args={['skyblue']} />
                <Blocks />
                <Environment />
                <OrbitControls makeDefault />
            </Suspense>
            <gridHelper args={[160, 20, 0xff0000, 'teal']} />
        </Canvas>
    );
}

export default MainCanvas;