
import * as THREE from 'three';
import { hUnit, pUnit, parts_material } from '../../globals';
import { state} from '../../globals';
import { Geometry, Base, Subtraction, Addition } from '@react-three/csg'
import { FlatPins } from './flatpins';
import React  from 'react';

export function Door(props,trans,color) {

  let shape = new THREE.Shape();
  shape.moveTo(0, 0);
  shape.lineTo(pUnit * props.currentPart.width, 0);
  shape.lineTo(pUnit * props.currentPart.width, 3 * props.currentPart.length * hUnit);
  shape.lineTo(0, 3 * props.currentPart.length * hUnit);
  shape.lineTo(0, 0);

  let extrusionSettings = {
    steps: 1,
    depth: pUnit * 2,  // depth to extrude
    bevelEnabled: false,

  };

  let shape2 = new THREE.Shape();
  shape2.moveTo(1, 0);
  shape2.lineTo(pUnit * props.currentPart.width - 1,);
  shape2.lineTo(pUnit * props.currentPart.width - 1, 3 * props.currentPart.length * hUnit - 2);
  shape2.lineTo(1, 3 * props.currentPart.length * hUnit - 2);
  shape2.lineTo(1, 0);

  let extrusionSettings2 = {
    steps: 1,
    depth: pUnit * 2 + 5,  // depth to extrude
    bevelEnabled: false,
  };

  // Create the extruded geometry
  let geometry = new THREE.ExtrudeGeometry(shape, extrusionSettings);
  let geometry2 = new THREE.ExtrudeGeometry(shape2, extrusionSettings2);

  geometry2.translate(0, 3, -3);
 
  let Left =0 ,hMid = pUnit * props.currentPart.width /2 ,Right = pUnit * props.currentPart.width;
  let Bottom =0 ,vMid = 3*hUnit * props.currentPart.length /2 ,Top = 3*hUnit * props.currentPart.length;


  shape = new THREE.Shape();
  shape.moveTo(Left, Bottom);
  shape.lineTo(Right, Bottom);
  shape.lineTo(Right, Top);
  shape.lineTo(Left, Top);
  shape.lineTo(Left, Bottom);


  shape2 = new THREE.Shape();   //letf bottom
  shape2.moveTo(Left+5, Bottom+5);
  shape2.lineTo(Right-5, Bottom+5);
  shape2.lineTo(Right-5, Top-5);
  shape2.lineTo(Left+5, Top-5);
  shape2.lineTo(Left+5, Bottom+5);

  shape.holes.push(shape2);

  var extrudeSettings = { depth:5, bevelEnabled: true, steps:4 };
  var gmo = new THREE.ExtrudeGeometry(shape, extrudeSettings);


  shape2 = new THREE.Shape();   //door desk
  shape2.moveTo(Left+5, Bottom+5);
  shape2.lineTo(Right-5, Bottom+5);
  shape2.lineTo(Right-5, Top-5);
  shape2.lineTo(Left+5, Top-5);
  shape2.lineTo(Left+5, Bottom+5);
  

  var shape3 = new THREE.Shape();   //door desk left hole
  shape3.moveTo(Left+7, Top-6);
  shape3.lineTo(Right/2-2, Top-6);
  shape3.lineTo(Right/2-2, Top /4*3-2);
  shape3.lineTo(Left+7, Top /4*3-2);
  shape3.lineTo(Left+7, Top-6);
  shape2.holes.push(shape3);

  shape3 = new THREE.Shape();   //door desk right hole
  shape3.moveTo(Right /2+2, Top-6);
  shape3.lineTo(Right-7, Top-6);
  shape3.lineTo(Right-7, Top /4*3-2);
  shape3.lineTo(Right /2+2, Top /4*3-2);
  shape3.lineTo(Right/2+2, Top-6);
  shape2.holes.push(shape3);

  var extrudeSettings1 = { depth:2, bevelEnabled: true, steps:4 };
  var gmo1 = new THREE.ExtrudeGeometry(shape2, extrudeSettings1);


  shape3 = new THREE.Shape();   //door knob
  shape3.arc(Right/4*3, Top /2, 2, 0, Math.PI*2);
  var extrudeSettings3 = { depth:5, bevelEnabled: false, steps:1 };
  var gmo_knob = new THREE.ExtrudeGeometry(shape3, extrudeSettings3);

  let flat = false;
  let hT = 3 * props.currentPart.length;
  let norow = [];
  let norowb = [];
  var rows = [];
  var id = 0;
  //while (++i <= len) rows.push([i,i*2])

  for (let i = 0; i < props.currentPart.width; i++) {
    for (let j = 0; j < 2; j++) {
      rows.push([i, j, id++])
    }
  }

  return (<mesh receiveShadow castShadow

    name={props.name}

    material={parts_material.clone()}
    material-color={color}

    material-transparent={trans}
    material-opacity={0.7}

    {...props}

    dispose={null}
  >

    <Geometry computeVertexNormals>
      <Base name="roof_incline" geometry={geometry} rotation={[0, -Math.PI / 2, 0]} position={[2 * pUnit, 0, 0]} />
      <Subtraction name="cavity1" geometry={geometry2} rotation={[0, -Math.PI / 2, 0]} position={[2 * pUnit - 1, -2, 0]} />
      <Addition name="door frame" geometry={gmo} rotation={[0, -Math.PI / 2, 0]} position={[5 , 0, 0]} />
      <Addition name="door desk" geometry={gmo1} rotation={[0, -Math.PI / 2, 0]} position={[3 , 0, 0]} />
      <Addition name="door desk" geometry={gmo_knob} rotation={[0, -Math.PI / 2, 0]} position={[3 , 0, 0]} />
 
     <FlatPins rows={rows} norow={norow} norowb={norowb} flat={flat} col={color} hT={hT} name={props.name} inmodel={props.inmodel}/>
    </Geometry>

  </mesh>)

}
