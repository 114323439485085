import React, { useContext } from 'react';
import { Button } from '@mui/material';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { useSnapshot } from 'valtio';
import { state } from '../../globals';
import { modelStore } from '../../store/modelStore';
import { SelectionContext } from '../../contexts/SelectionContext'; // Import the context


function DetachPartButton() {

    const { currentb, setCurrentb } = useContext(SelectionContext); // Access global state

    const handleDetach = () => {
        modelStore.detachPart(currentb);
        // Reset selection state after detaching
        setCurrentb(null);
        state.current = null;
        state.current2 = null;
    };

    if (currentb === null) return null;

    return (
        <Button 
            variant="outlined" 
            color="error" 
            onClick={handleDetach}
            size="small"
            startIcon={<LinkOffIcon />}
            sx={{
                textTransform: 'none',
                minWidth: '100px',
                '&:hover': {
                    backgroundColor: 'rgba(211, 47, 47, 0.04)'
                }
            }}
        >
            Detach Part {currentb}
        </Button>
    );
}

export default DetachPartButton;