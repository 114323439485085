// src/services/authService.js
import queryString from 'query-string';
import { proxy, ref } from 'valtio';
import { port } from '../globals';


const domainName = window.location.hostname;

const authState = proxy({
    username: '',
    accessToken: '',
});


const logoff = async () => {

//     let result = await fetch('https://vps-417c5415.vps.ovh.net/session/token', {
//         ethod: 'GET',
//         credentials: 'include', // Include cookies for authenticated requests
//         headers: {
//           'Accept': 'application/json',
//           'Content-Type': 'application/json',
//         },
//     }).then((res) => res.json());

//     await fetch('https://vps-417c5415.vps.ovh.net/session/token')
//       .then(response => response.text())
//       .then(token => {
//         // Logout request with CSRF token
//         fetch('https://vps-417c5415.vps.ovh.net/user/logout', {
//           method: 'POST',
//           headers: {
//             'X-CSRF-Token': token,
//             'Content-Type': 'application/json',
//           },
//         })
//         .then(response => {
//           if (response.ok) {
//             console.log('Logout successful');
//             window.location.href = window.location.origin; // Redirect to the homepage
//           } else {
//             console.error('Logout failed');
//           }
//         })
//         .catch(error => console.error('Error during logout:', error));
//       })
//       .catch(error => console.error('Error fetching CSRF token:', error));
//   };
  

    authService.authState.username = '';
    authService.authState.accessToken = '';
    if (domainName === 'localhost') {
        //window.location.href = 'https://vps-417c5415.vps.ovh.net/user/logout?redirect_uri=' + encodeURIComponent(window.location.origin);

        const logoutUrl = 'https://vps-417c5415.vps.ovh.net/user/logout?destination_url='+ encodeURIComponent(window.location.origin)
        window.location.href = logoutUrl;

    } else     if (domainName === 'vps-417c5415.vps.ovh.net') {

        const logoutUrl = 'https://vps-417c5415.vps.ovh.net/user/logout?redirect_url='+ encodeURIComponent(window.location.origin)
        window.location.href = logoutUrl;

    }

}


const initiateLogin = () => {
    let logURI
    let tokenHost

    if (domainName === 'localhost') {
        // 'vps-417c5415.vps.ovh.net') {      


        tokenHost = 'https://vps-417c5415.vps.ovh.net';
        let redirect = 'http://localhost:3000';
        const client_id = 'glJiFSEdUbKlbWoSgT2HtwHHp7xevOVL8MCd6WY8qT8';
        const client_secret = '1q2w3e';

        logURI = tokenHost + '/oauth/authorize?client_id=' + client_id + '&client_secret=' + client_secret + '&response_type=code&redirect_uri=' + redirect + '&scope=consum';

    }



    if (domainName === 'vps-417c5415.vps.ovh.net') {
        tokenHost = 'https://vps-417c5415.vps.ovh.net';
        let redirect = 'http://vps-417c5415.vps.ovh.net:8080';
        const client_id = 'glJiFSEdUbKlbWoSgT2HtwHHp7xevOVL8MCd6WY8qT8';
        const client_secret = '1q2w3e';

        logURI = tokenHost + '/oauth/authorize?client_id=' + client_id + '&client_secret=' + client_secret + '&response_type=code&redirect_uri=' + redirect + '&scope=consum';

    }
    // else {
    //     tokenHost = (port !== 'none') ? 'http://i-blocks.local' : 'https://www.i-blocks.com';
    //     let redirect = (port !== 'none') ? 'http://localhost:3000' : 'https://app.i-blocks.com/';
    //     const client_id = 'glJiFSEdUbKlbWoSgT2HtwHHp7xevOVL8MCd6WY8qT8';
    //     const client_secret = '1q2w3e';

    //     if (domainName === 'app.i-blocks.local') {
    //         tokenHost = 'http://i-blocks.local';
    //         redirect = 'http://app.i-blocks.local';

    //     }
    //     logURI = tokenHost + '/oauth/authorize?client_id=' + client_id + '&client_secret=' + client_secret + '&response_type=code&redirect_uri=' + redirect + '&scope=consum';

    // }
    console.log('logURI=', logURI);
    window.location.href = logURI;
};

const saveCredentials = (data) => {
    authState.username = data.username;
    authState.accessToken = data.access_token;
};


const initializeAuth = async () => {

    console.log('domainName ' + domainName);


    const parsed = queryString.parse(window.location.search);
    if (parsed.code) {
        let cd = parsed.code;
        let lurl;
        let redirect = '';

        if (domainName === 'app.i-blocks.local') {
            redirect = 'http://app.i-blocks.local';
        } else {
            redirect = (port !== 'none') ? 'http://localhost:3000' : 'https://app.i-blocks.com/';
        }

        if (domainName === 'localhost') {
            //redirect = 'https://vps-417c5415.vps.ovh.net';
            redirect = 'http://localhost:3000';
        }

        if (domainName === 'vps-417c5415.vps.ovh.net') {
            //redirect = 'https://vps-417c5415.vps.ovh.net';
            redirect = 'http://vps-417c5415.vps.ovh.net:8080';
        }

        try {
            if (port !== 'none')
                lurl = "http://localhost:" + port + "/connect";
            else
                lurl = "/connect";

            const response = await fetch(lurl, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ cd, redirect }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.status);
            }

            const data = await response.json();
            saveCredentials(data);
        } catch (error) {
            console.error('Error:', error);
        }
        window.history.replaceState({}, null, window.location.pathname);
    }




    //test
    // else if (domainName === 'localhost') {
    //     saveCredentials({ username: 'alex', access_token: 'TOKEN123' });
    // }
    // else if (domainName === 'vps-417c5415.vps.ovh.net') {
    //     saveCredentials({ username: 'alex', access_token: 'TOKEN123' });
    // }

};



const authService = {
    initiateLogin,
    initializeAuth,
    logoff,
    authState,
};

export { authService };