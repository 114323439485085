import React, { useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { DataProvider } from './contexts/DataContext'; // Corrected export
import AppLayout from './components/AppLayout';
import { modelStore } from './store/modelStore';
import { authService } from './services/authService';
import { SelectionContext } from './contexts/SelectionContext';
import { useState } from 'react';

function App() {
    const { loadInitialModel } = modelStore;
    const [currentb, setCurrentb] = useState(null); // Define global state here

    useEffect(() => {
        authService.initializeAuth(); // Handle initial auth check/setup
        loadInitialModel(); // Load a default model or start with an empty one.
    }, [loadInitialModel]);

    return (
        <SelectionContext.Provider value={{ currentb, setCurrentb }}>
        <DataProvider>
            <AppLayout />
        </DataProvider>
        </SelectionContext.Provider>
    );
}

export default App;