import React from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { Environment } from '../../Environment';
import { CurBlock } from '../Parts/curblock';
import { useSnapshot } from 'valtio';
import { modelStore } from '../../store/modelStore';
import { orbit } from '../../globals';

function PreviewCanvas() {

  const { currentPart } = useSnapshot(modelStore); // Access current part

  return (
      <Canvas shadows camera={{ position: [-150, 100, 150], fov: 25 }}>
          <color attach="background" args={['lightgreen']} />
          {currentPart && currentPart.name !== 'empty' && (
              <CurBlock currentPart={currentPart} inmodel={false} />
          )}
          <Environment />
          <OrbitControls target={[orbit.x, 0, orbit.z]} />
          <gridHelper args={[160, 20, 0xff0000, 'teal']} />
      </Canvas>
  );
}

export default PreviewCanvas;