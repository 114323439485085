import { useState, useEffect } from 'react'
import { port } from '../globals';


let lurl;

async function loadData() {
  if (port !== 'none')
    lurl = "http://localhost:" + port + "/parts";
  else
    lurl = "/parts";

  let result = await fetch(lurl).then((res) => res.json());

  return result;
}

async function sendData(objects, image64) {

 // let data = JSON.stringify(objects);//, (key, value) => key === 'rows' ? undefined : value);
  try {

    if (port !== 'none')
      lurl = "http://localhost:" + port + "/parts";
    else
      lurl = "/parts";


    const response = await fetch(lurl
      //   const response = await fetch('http://localhost:4000/models'     
      , {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: 
        JSON.stringify({
          file: image64,
          data: objects,
        }),
        
        // data,
      });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return result.partId;
  } catch (error) {
    console.error('Error:', error);
  }


}

async function sendImage(image64) {

  let lurl;

  if (port !== 'none')
    lurl = "http://localhost:" + port + "/parts";
  else
    lurl = "/parts";

  await fetch(lurl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body:
    JSON.stringify({
        file: image64,
    }),
  })
    .then((response) => response.json())
    .then((data) => console.log(data))
    .catch((error) => console.error("Error:", error));


}

export function SendPartImage(image64) {
  sendImage(image64)
}

export async function savePartToServer(objects, image64) {

  const value = await sendData(objects, image64);
  return value;
};

export async function LoadParts() {

  let rc = await loadData();  //// should be let! not const!

  return rc;

}