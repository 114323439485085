
import * as THREE from 'three';
import { hUnit, pUnit, dUnit, pinHeight, parts_material } from '../../globals';
import { state, box, cyl, logo } from '../../globals';
import { Geometry, Base, Subtraction, Addition } from '@react-three/csg'
import { FlatPins } from './flatpins';
import React  from 'react';

export function Roof30_2(props,trans,color) {

  let flat = false;
  let hT = 1;
  let norowb = [];
  let norow = [];
  var rows = [];
  var id = 0;

  let shape = new THREE.Shape();
  let shape2 = new THREE.Shape();


  let extrusionSettings = {
    steps: 1,
    depth: pUnit * props.currentPart.length,  // depth to extrude
    bevelEnabled: false,

  };

  let extrusionSettings2 = {
    steps: 1,
    depth: pUnit * props.currentPart.length - 2,  // depth to extrude
    bevelEnabled: false,
  };

      shape.moveTo(0, 0);
      shape.lineTo(pUnit * props.currentPart.width, 0);
      shape.lineTo(pUnit * (props.currentPart.width - 1), hUnit);
      shape.lineTo(pUnit, hUnit);
      shape.lineTo(0, 0);

      shape2.moveTo(0.5, 0);
      shape2.lineTo(pUnit * props.currentPart.width - 0.5, 0);
      shape2.lineTo(pUnit * (props.currentPart.width - 1) - 0.5, hUnit - 0.5);
      shape2.lineTo(pUnit - 0.5, hUnit - 0.5);
      shape2.lineTo(0.5, 0);
      norow = [0, props.currentPart.width - 1];
      norowb = [0, props.currentPart.width - 1];

  // Create the extruded geometry
  let geometry = new THREE.ExtrudeGeometry(shape, extrusionSettings);
  let geometry2 = new THREE.ExtrudeGeometry(shape2, extrusionSettings2);

  //while (++i <= len) rows.push([i,i*2])

  for (let i = 0; i < props.currentPart.width; i++) {
    for (let j = 0; j < props.currentPart.length; j++) {
      rows.push([i, j, id++])
    }
  }

  return (<mesh receiveShadow castShadow

    name={props.currentPart.name}

    material={parts_material.clone()}
    material-color={color}

    material-transparent={props.bTrans}
    material-opacity={props.bTrans ? 0.7 : 1}

    {...props}

    dispose={null}
  >

    <Geometry computeVertexNormals>
      <Base name="roof_incline" geometry={geometry} rotation={[0, -Math.PI / 2, 0]} position={[pUnit * props.currentPart.length, 0, 0]} />
      <Subtraction name="cavity1" geometry={geometry2} rotation={[0, -Math.PI / 2, 0]} position={[pUnit * props.currentPart.length - 1, -2, 0]} />

      <FlatPins rows={rows} norow={norow} norowb={norowb} flat={flat} col={color} hT={hT} name={props.currentPart.name} inmodel={props.inmodel} />
    </Geometry>

  </mesh>)

}
