// src/components/UI/ControlsPanel.js
import React from 'react';
import { Button, Typography, Divider } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import { useSnapshot } from 'valtio';
import { modelStore } from '../../store/modelStore';
import { uiStore } from '../../store/uiStore';
import { authService } from '../../services/authService';
import ModelDialog from './Dialogs/ModelDialog';
import ModelListDialog from './Dialogs/ModelListDialog';
import CleanModelDialog from './Dialogs/CleanModelDialog';
import DetachPartButton from './DetachPartButton';


function ControlsPanel() {
    const { model } = useSnapshot(modelStore);
    const { username } = useSnapshot(authService.authState);
    const { isModelDialogOpen, isModelListDialogOpen, isCleanModelDialogOpen } = useSnapshot(uiStore);

    // Handle button clicks
    const handleSaveClick = () => {
        uiStore.openModelDialog();
    };

    const handleLoadClick = () => {
        uiStore.openModelListDialog();
    };

    const handleCleanClick = () => {
        uiStore.openCleanModelDialog();
    };

    const handleLogoff = async () => {
        // Reset auth state
        await authService.logoff()
        // authService.authState.username = '';
        // authService.authState.accessToken = '';
        // window.location.href = 'https://oauth-provider.com/logout?redirect_uri=' + encodeURIComponent(window.location.origin);

    };

    return (
        <div style={{ 
            display: 'flex', 
            alignItems: 'center',
            gap: '16px', 
            padding: '8px 16px',
            borderBottom: '1px solid #e0e0e0',
            backgroundColor: '#fafafa'
        }}>
            {model && (
                <Typography 
                    variant="button" 
                    style={{ 
                        padding: '4px 8px',
                        backgroundColor: '#f5f5f5',
                        borderRadius: '4px',
                        whiteSpace: 'nowrap',
                        fontSize: '0.75rem',
                        color: '#666'
                    }}
                >
                    Model: {model.model_name}
                </Typography>
            )}
            
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <Button 
                    variant="outlined" 
                    onClick={handleSaveClick}
                    size="small"
                    startIcon={<SaveIcon />}
                    sx={{
                        textTransform: 'none',
                        minWidth: '100px',
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.04)'
                        }
                    }}
                >
                    Save
                </Button>
                <ModelDialog 
                    open={isModelDialogOpen} 
                    onClose={uiStore.closeModelDialog} 
                    onSave={modelStore.saveModel} 
                />

                <Button 
                    variant="outlined" 
                    color="primary" 
                    onClick={handleLoadClick}
                    size="small"
                    startIcon={<FolderOpenIcon />}
                    sx={{
                        textTransform: 'none',
                        minWidth: '100px',
                        '&:hover': {
                            backgroundColor: 'rgba(25, 118, 210, 0.04)'
                        }
                    }}
                >
                    Load
                </Button>
                <ModelListDialog 
                    open={isModelListDialogOpen} 
                    onClose={uiStore.closeModelListDialog} 
                    onSelectModel={modelStore.loadModel} 
                    username={username} 
                />

                <Divider orientation="vertical" flexItem />

                <Button 
                    variant="outlined" 
                    // color="warning" 
                    onClick={handleCleanClick}
                    size="small"
                    startIcon={<CleaningServicesIcon />}
                    sx={{
                        textTransform: 'none',
                        minWidth: '100px',
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.04)'
                        }
                    }}
                >
                    Clean
                </Button>
                <CleanModelDialog 
                    open={isCleanModelDialogOpen} 
                    onConfirm={modelStore.cleanModel} 
                    onClose={uiStore.closeCleanModelDialog}
                />
                
                <DetachPartButton />
            </div>

            <div style={{ marginLeft: 'auto' }}>
                {username === '' ? (
                    <Button 
                        variant="outlined" 
                        onClick={authService.initiateLogin}
                        size="small"
                        startIcon={<LoginIcon />}
                        sx={{
                            textTransform: 'none',
                            minWidth: '100px',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.04)'
                            }
                        }}
                    >
                        Login
                    </Button>
                ) : (
                    <Button 
                        variant="outlined" 
                        onClick={handleLogoff}
                        size="small"
                        startIcon={<LogoutIcon />}
                        sx={{
                            textTransform: 'none',
                            minWidth: '120px',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.04)'
                            }
                        }}
                    >
                        {username}
                    </Button>
                )}
            </div>
        </div>
    );
}

export default ControlsPanel;