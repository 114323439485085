import React from 'react';
import { 
    Dialog, 
    DialogTitle, 
    DialogContent, 
    DialogActions, 
    Button, 
    Typography,
    Box
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

function CleanModelDialog({ open, onClose, onConfirm }) {
    const handleConfirm = () => {
        onConfirm();
        onClose();
    };

    return (
        <Dialog 
            open={open} 
            onClose={onClose}
            PaperProps={{
                sx: {
                    borderRadius: '8px',
                    minWidth: '400px'
                }
            }}
        >
            <DialogTitle sx={{ 
                borderBottom: '1px solid #e0e0e0',
                padding: '16px 24px',
                backgroundColor: '#fafafa'
            }}>
                <Typography variant="h6" component="div" sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    gap: '8px',
                    color: 'default',
                    fontWeight: 500
                }}>
                    <DeleteForeverIcon sx={{ fontSize: 24 }} />
                    Clean Model
                </Typography>
            </DialogTitle>
            <DialogContent sx={{ 
                padding: '24px',
                backgroundColor: '#ffffff'
            }}>
                <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: '16px',
                    padding: '16px',
                    backgroundColor: 'lightgrey',
                    borderRadius: '4px',
                    marginBottom: '16px'
                }}>
                    <WarningAmberIcon sx={{ color: 'warning.main', fontSize: 24 }} />
                    <Typography variant="body2" color="black">
                        This action will remove all parts from your current model. This cannot be undone.
                    </Typography>
                </Box>
                <Typography variant="body1" color="text.secondary">
                    Are you sure you want to clean the model?
                </Typography>
            </DialogContent>
            <DialogActions sx={{ padding: '16px', gap: '8px' }}>
                <Button 
                    onClick={onClose}
                    size="small"
                    startIcon={<CloseIcon />}
                    sx={{
                        textTransform: 'none',
                        minWidth: '100px',
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.04)'
                        }
                    }}
                >
                    Cancel
                </Button>
                <Button 
                    onClick={handleConfirm}
                    variant="contained"
                    color="warning"
                    size="small"
                    startIcon={<CheckIcon />}
                    sx={{
                        textTransform: 'none',
                        minWidth: '100px',
                        '&:hover': {
                            backgroundColor: 'warning.dark'
                        }
                    }}
                >
                    Clean
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CleanModelDialog;
