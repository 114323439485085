import React from 'react';
import { Grid, Paper, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MainCanvas from './Canvas/MainCanvas';
import PreviewCanvas from './Canvas/PreviewCanvas';
import ControlsPanel from './UI/ControlsPanel';
import PartsTable from './UI/PartsTable';
import { handleTableClick } from '../services/partTableService';
import createAndSavePart from '../services/partService.js'
import { addPartToModel } from '../services/partService.js';

import FormDialog from './UI/Dialogs/FormDialog';  // Import FormDialog
import { DataContext } from '../contexts/DataContext';
import { LoadParts } from '../serverCalls/SaveParts.js';


function AppLayout() {

    const { setData } = React.useContext(DataContext);


    const handleCreatePart = async (part_type, len, wd, colr, is_transparent, image64) => {
        try {
            const createdPart = await createAndSavePart(part_type, len, wd, colr, is_transparent, image64); // Assuming this returns the new part
             // After successful creation, refresh the parts data
             const parts = await LoadParts();
             setData(parts);

        } catch (error) {
            console.error("Error creating and saving part:", error);
        }
    };

    return (
        <Grid container spacing={1} sx={{ height: { sm: '50vh', xs: '30vh', lg: '100vh' } }}>
            <Grid item xs={12} md={8} sx={{ height: { sm: '50vh', xs: '30vh', lg: '100vh' } }}>
                <Paper style={{ height: '100%' }}>
                    <div style={{ width: '100%', height: '95%' }}>
                        <ControlsPanel />
                        <MainCanvas />
                    </div>
                </Paper>
            </Grid>
            <Grid item xs={12} md={4} container direction="column">
                <Grid item style={{ flex: 1 }}>
                    <Paper style={{ height: '100%' }}>
                        <PreviewCanvas />
                    </Paper>
                </Grid>
                <Grid item style={{ flex: 2 }}>
                    <Paper sx={{ height: '100%' }}>
                        <div style={{ 
                            padding: '16px', 
                            display: 'flex', 
                            gap: '8px',
                            borderBottom: '1px solid #e0e0e0'
                        }}>
                            <FormDialog OnCreatePart={handleCreatePart} />
                            <Button 
                                variant="outlined" 
                                size="small" 
                                onClick={addPartToModel}
                                startIcon={<AddIcon />}
                                color="primary"
                                sx={{
                                    textTransform: 'none',
                                    minWidth: '120px',
                                    '&:hover': {
                                        backgroundColor: 'rgba(25, 118, 210, 0.04)'
                                    }
                                }}
                            >
                                Add to Model
                            </Button>
                        </div>
                        <div style={{ height: '100%' }}>
                        
                        <PartsTable onRowClick={handleTableClick} />
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default AppLayout;