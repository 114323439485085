
import * as THREE from 'three';
import { dUnit, hUnit, pUnit, parts_material } from '../../globals';
import { Geometry, Base, Subtraction, Addition } from '@react-three/csg'
import { React, useRef } from 'react';
import { Sensor } from './sensor';



export function AngleBeam(props, trans1, color1) {

  let { currentPart, rotation, inmodel, trans, color, name } = props;
  let u_name = currentPart.name

  if (trans1 !== undefined && color1 !== undefined) {
    trans = trans1;
    color = color1;
  }
  if (name !== undefined) {
    u_name = name;
  }

  const length = currentPart.length * pUnit;
  const length1 = currentPart.width * pUnit;
  // const width = currentPart.width * dUnit;
  const height = pUnit;
  const holeCount = currentPart.length;
  const holeCount1 = currentPart.width;
  const holeRadius = dUnit / 2;
  const spacing = pUnit;
  const angle = -Math.PI / 4

  // Create beam geometry with holes
  const createBeamGeometry = () => {
    const shape = new THREE.Shape();

    const cornerRadius = pUnit / 2; // Adjust this value to change corner roundness

    // Create rounded rectangle
    const halfLength = length / 2;
    const halfHeight = height / 2;

    shape.moveTo(-halfLength + cornerRadius, -halfHeight);
    // Bottom edge
    shape.lineTo(halfLength - cornerRadius, -halfHeight);


    shape.arc(0, halfHeight, cornerRadius, -Math.PI / 2, Math.PI / 2, false);

    // Top edge
    shape.lineTo(-halfLength + cornerRadius, halfHeight);
    shape.arc(0, -halfHeight, cornerRadius, Math.PI / 2, -Math.PI / 2, false);

    // Add holes


    for (let i = 1; i <= holeCount; i++) {
      const hole = new THREE.Path();
      const x = -length / 2 - pUnit / 2 + (spacing * i);
      hole.absarc(x, 0, holeRadius, 0, Math.PI * 2, true);
      shape.holes.push(hole);
    }

    // Extrude the shape
    const extrudeSettings = {
      depth: dUnit,
      bevelEnabled: false
    };

    return new THREE.ExtrudeGeometry(shape, extrudeSettings);
  };

  const createBeamGeometry1 = () => {
    const shape = new THREE.Shape();
    const cornerRadius = pUnit / 2; // Adjust this value to change corner roundness

    // Create rounded rectangle
    const halfLength = length1 / 2;
    const halfHeight = height / 2;

    shape.moveTo(-halfLength + cornerRadius, -halfHeight);
    // Bottom edge
    shape.lineTo(halfLength - cornerRadius, -halfHeight);


    shape.arc(0, halfHeight, cornerRadius, -Math.PI / 2, Math.PI / 2, false);

    // Top edge
    shape.lineTo(-halfLength + cornerRadius, halfHeight);
    shape.arc(0, -halfHeight, cornerRadius, Math.PI / 2, -Math.PI / 2, false);


    for (let i = 1; i <= holeCount1; i++) {
      const hole = new THREE.Path();
      const x = -length1 / 2 - pUnit / 2 + (spacing * i);
      hole.absarc(x, 0, holeRadius, 0, Math.PI * 2, true);
      shape.holes.push(hole);
    }

    // Extrude the shape
    const extrudeSettings = {
      depth: dUnit,
      bevelEnabled: false
    };

    return new THREE.ExtrudeGeometry(shape, extrudeSettings);
  };

  // Calculate sensor positions (same as holes)
  const getSensorPositions = () => {
    const positions = [];

    for (let i = 1; i <= holeCount; i++) {
      const x = -length / 2 - pUnit / 2 + (spacing * i);
      positions.push([x, 0, dUnit / 2]); // Position at center of beam thickness

    }
    return positions;
  };

  // Calculate sensor positions (same as holes)
  const getSensorPositions1 = () => {
    const positions = [];

    for (let i = 1; i <= holeCount1 - 1; i++) {
      const x = -length1 / 2 - pUnit / 2 + (spacing * i);
      positions.push([x, 0, dUnit / 2]); // Position at center of beam thickness

    }
    return positions;
  };

  const sensorPositions = getSensorPositions();
  const sensorPositions1 = getSensorPositions1();

  const geometry = createBeamGeometry();
  const geometry1 = createBeamGeometry1();


  return (
    <group name={currentPart.name}
      {...props} //put it to div to proper position both parts
    >
      <mesh receiveShadow castShadow rotation={rotation} position={[length / 2 - pUnit / 2, 0, 0]}

        name={currentPart.name}
        material={parts_material.clone()}
        material-color={color}
        material-transparent={trans}  //{props.CurrentPartbTrans}
        material-opacity={0.7} //{props.CurrentPartbTrans ? 0.7 : 1}

        {...props}

        dispose={null}
      >

        <Geometry computeVertexNormals >
          <Base name="beam" geometry={geometry} />
        </Geometry>
        {/* Sensors */}
        {sensorPositions.map((position_w, index) => {
          return (
            <Sensor
              key={5000 + index}
              position={position_w}
              rotation={[Math.PI / 2, 0, 0]}
              name={'bm1_' + currentPart.name + index}
              inmodel={inmodel}
            />
          );
        })}
      </mesh>
      <mesh receiveShadow castShadow position={[(length1 / 2 - pUnit / 2) * Math.sin(angle), (length1 / 2 - pUnit / 2) * Math.cos(angle), 0]} rotation={[0, 0, angle]}

        name={currentPart.name}
        material={parts_material.clone()}
        material-color={color}
        material-transparent={trans}  //{props.CurrentPartbTrans}
        material-opacity={0.7} //{props.CurrentPartbTrans ? 0.7 : 1}

        {...props}

        dispose={null}
      >
        <Geometry computeVertexNormals  >
          <Base name="beam1" geometry={geometry1} />
        </Geometry>
        {/* Sensors */}
        {sensorPositions1.map((position_w, index) => {
          return (
            <Sensor
              key={5000 + index}
              position={position_w}
              rotation={[Math.PI / 2, 0, 0]}
              name={'bm2_' + currentPart.name + index}
              inmodel={inmodel}
            />
          );
        })}

      </mesh >
    </group >

  )
}
