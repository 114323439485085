// src/services/partService.js
// Assume you have a function to save parts to the server
import { savePartToServer } from '../serverCalls/SaveParts';  // Adjust import path as needed
import { port } from '../globals';
import { modelStore } from '../store/modelStore';
import { addToModel } from '../AddToModel.js';

async function createAndSavePart(part_type, len, wd, colr, is_transparent, image64) {
    const new_part = { part_type: part_type, part_color: colr, part_length: len, part_width: wd, is_transparent: is_transparent };

    try {
        let partId = await savePartToServer(new_part, image64);
        new_part.part_id = partId;
        new_part.part_image_URL = 'img/' + partId + '.png';
        const image_txt = image64;
        new_part.part_image_txt = image_txt;
        return new_part; // Return the new part with ID and URL
    } catch (err) {
        console.error(err);
        throw err; // Re-throw the error so the caller can handle it
    }
}

export const addPartToModel = () => {
    if (!modelStore.currentPart) return;
    
    // Use the existing addToModel function to handle part positioning and rotation
    addToModel(modelStore.currentPart, modelStore.objects.length);

    // Let modelStore handle the state updates
    modelStore.addPartToModel();
  }

// const partService = {
//     createAndSavePart,
// };
export default createAndSavePart;