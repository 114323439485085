// src/components/Parts/Blocks.js
import React from 'react';
import { CurBlock } from './curblock';
import { useSnapshot } from 'valtio';
import { modelStore } from '../../store/modelStore';

function Blocks() {
    const { objects } = useSnapshot(modelStore);

    return (
        <>
            {objects.map((data) => (
                <CurBlock key={data.id} currentPart={data} inmodel={true} />
            ))}
        </>
    );
}

export default Blocks;