
import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import { dUnit, hUnit, pUnit, parts_material } from '../../globals';
import { Geometry, Base, Subtraction, Addition } from '@react-three/csg'
import { blue, red } from '@mui/material/colors';
import { useFrame } from '@react-three/fiber';
import { Sensor } from './sensor';


export const Wheel = (props, trans1, color1) => {
  //{ radius = 2, thickness = 0.5 }) => {
  let { currentPart, rotation, inmodel, trans, color, name } = props;
  let u_name = currentPart.name

  if (trans1 !== undefined && color1 !== undefined) {
    trans = trans1;
    color = color1;
  }
  if (name !== undefined) {
    u_name = name;
  }


  const radius = currentPart.length * pUnit / 2;
  const thickness = currentPart.width * pUnit / 4;


  // Materials
  // const rimMaterial = new THREE.MeshPhongMaterial({ color: red }); // Gray rim
  // const tireMaterial = new THREE.MeshPhongMaterial({ color: blue }); // Black tire

  const rimMaterial = new THREE.MeshStandardMaterial({
    color: color, // Inherit the color
    metalness: 0,                     // Plastic is typically not metallic
    roughness: 0.5,                   // Adjust roughness for desired shininess
    // You can add more properties to simulate plastic further:
    // specular: new THREE.Color(0x111111), // Subtle specular highlight color
    // shininess: 30,                      // Size of the specular highlight (if using MeshPhongMaterial-like specular)
    // reflectivity: 0,                  // Plastic doesn't reflect much
    // refractionRatio: 0.98,            // Slight refraction (optional)
  });

  const tireMaterial = new THREE.MeshStandardMaterial({
    color: 'black', // Inherit the color
    metalness: 0,                     // Plastic is typically not metallic
    roughness: 0.5,                   // Adjust roughness for desired shininess
    // You can add more properties to simulate plastic further:
    // specular: new THREE.Color(0x111111), // Subtle specular highlight color
    // shininess: 30,                      // Size of the specular highlight (if using MeshPhongMaterial-like specular)
    // reflectivity: 0,                  // Plastic doesn't reflect much
    // refractionRatio: 0.98,            // Slight refraction (optional)
  });


  // Wheel dimensions
  const tireThickness = thickness * 0.6; // 60% of wheel thickness

  // Rim geometry (inner cylinder)
  const rimGeometry = new THREE.CylinderGeometry(
    radius-thickness/2, // Smaller radius for rim
    radius-thickness/2, // Larger radius for rim
    thickness,
    32
  );
  rimGeometry.rotateX(Math.PI / 2); // Rotate rim to face upwards

  // const rim = new THREE.Mesh(rimGeometry, rimMaterial);

  // Tire geometry (outer ring)
  const tireGeometry = new THREE.TorusGeometry(
    radius,           // Radius to center of torus
    thickness,    // Tube thickness
    16,              // Radial segments
    32               // Tubular segments
  );

  const holeRadius = dUnit / 2; // Adjust for desired hole size
  const holeGeometry = new THREE.CylinderGeometry(holeRadius, holeRadius, thickness * 1.1, 32); // Make slightly longer than thickness
  holeGeometry.rotateX(Math.PI / 2); // Rotate the hole to align with the wheel axis.

  // rimTexture = new THREE.TextureLoader().load('/textures/rim.jpg');
  // tireTexture = new THREE.TextureLoader().load('/textures/tire.jpg');

  return (
    <group name={currentPart.name}

      {...props} //put it to div to proper position both parts

    >
      <mesh receiveShadow castShadow
        material={rimMaterial}
        // material-color={color}
        material-transparent={trans}  //{props.CurrentPartbTrans}
        material-opacity={0.7} //{props.CurrentPartbTrans ? 0.7 : 1}
        // {...props}
        dispose={null}
      >

        <Geometry>
          <Base geometry={rimGeometry} name="rim" />
          <Subtraction geometry={holeGeometry} />
        </Geometry>
      </mesh>

      <mesh receiveShadow castShadow

        material={tireMaterial}
        // material-color={'black'}
        material-transparent={trans}  //{props.CurrentPartbTrans}
        material-opacity={0.7} //{props.CurrentPartbTrans ? 0.7 : 1}
        // {...props}
        dispose={null}

      >
        <Geometry>
          <Base geometry={tireGeometry} name='tire' />
        </Geometry>

        <Sensor rotation={[Math.PI / 2, 0, 0]}
          name={'wh_' + currentPart.name}
          inmodel={inmodel}
        />
      </mesh>
    </group>
  )

}

