import * as THREE from 'three'
import { proxy /*, useSnapshot*/ } from 'valtio'



export const state = proxy({
  currentb:null, current: null, current2: null, currentInModel: null,
  currentInModel2: null, mode: 0, step: 0, stepInModel: 0,
  // px: 0, py: 0, pz: 0, mx: 0, my: 0, mz: 0
});

export const p = new THREE.Vector3(0,0,0);
export const m = new THREE.Vector3(0,0,0);
export const p2 = new THREE.Vector3(0,0,0);
export const m2 = new THREE.Vector3(0,0,0);


export const p_dir = new THREE.Vector3(0,0,0);
export const m_dir = new THREE.Vector3(0,0,0);
export const p2_dir = new THREE.Vector3(0,0,0);
export const m2_dir = new THREE.Vector3(0,0,0);


export const orbit = new THREE.Vector3(0,0,0);
export const modelRotation = new THREE.Vector3(0,0,0);

export const box = new THREE.BoxGeometry();
export const cyl = new THREE.CylinderGeometry();


export const logo = new THREE.CylinderGeometry(1.2, 1.0, 1.5, 6);


export const port = process.env.REACT_APP_PORTK || 'none';


// console.log(process.env.REACT_APP_PORTK);
// console.log(port);


export const hUnit = 3.2;
export const pUnit = 8;
export const dUnit = 4.8;
export const pinHeight = 1.7;



export const parts_material = new THREE.MeshStandardMaterial({
  roughness: 0.4,         // Moderate roughness for a smooth but not overly glossy look
  metalness: 0.1,         // Low metalness to avoid a metallic sheen
  envMapIntensity: 0.5    // Subtle environment reflection for a plastic shine (optional)
});
// const fontLoader = new FontLoader();
// let gm;

// fontLoader.load('font.json', function (font) {

//   gm = new TextGeometry('I BQ', {
//     // font: font,
//     // size: 1,
//     // height: 0.1, // embossing depth
//     // curveSegments: 12,
//     // bevelEnabled: true,
//     // bevelThickness: 0.1,
//     // bevelSize: 0.1,
//     // bevelOffset: 0,
//     // bevelSegments: 8,
//   });

//   TextGeom = gm;
//   console.log("gmg=",TextGeom.groups.length);
  
// })


// const font = await useLoader(FontLoader, 'font.json');

// const gm = new TextGeometry('I BQ', {
//   font: font,
//   size: 1,
//   height: 0.1, // embossing depth
//   curveSegments: 12,
//   bevelEnabled: true,
//   bevelThickness: 0.1,
//   bevelSize: 0.1,
//   bevelOffset: 0,
//   bevelSegments: 8,
// });


