import { m, m2, modelRotation, p, p2, p_dir, p2_dir, m_dir, m2_dir } from "./globals.js"
import * as THREE from 'three'
import { state } from './globals.js'

function vOut(v) {
    return [Math.round((v.x - 3.9) / 8), Math.round((v.y - 1.2) / 9.6), Math.round((v.z - 3.9) / 8)]
}


// Define the transformation matrices
function defineTransformationMatrices(p, p2, p_dir, m, m2, m_dir) {
    // Step 1: Create the first transformation matrix
    // This matrix will transform from part coordinates to an intermediate space
    const partToIntermediate = new THREE.Matrix4();
    
    // Calculate the translation component to move p to the origin
    const translation = new THREE.Vector3().subVectors(new THREE.Vector3(), p);
    
    // Create basis vectors for the part coordinate system
    const xAxis = new THREE.Vector3().subVectors(p2, p).normalize();
    const yAxisTemp = p_dir.clone().normalize();
    const zAxis = new THREE.Vector3().crossVectors(xAxis, yAxisTemp).normalize();
    const yAxis = new THREE.Vector3().crossVectors(zAxis, xAxis).normalize();
    
    // Set the rotation component of the matrix
    partToIntermediate.makeBasis(xAxis, yAxis, zAxis);
    
    // Apply the translation
    partToIntermediate.setPosition(translation);
    
    // Step 2: Create the second transformation matrix
    // This matrix will transform from the intermediate space to canvas coordinates
    const intermediateToCanvas = new THREE.Matrix4();
    
    // Calculate the canvas coordinate system basis vectors
    const canvasXAxis = new THREE.Vector3().subVectors(m2, m).normalize();
    const canvasYAxisTemp = m_dir.clone().normalize();
    const canvasZAxis = new THREE.Vector3().crossVectors(canvasXAxis, canvasYAxisTemp).normalize();
    const canvasYAxis = new THREE.Vector3().crossVectors(canvasZAxis, canvasXAxis).normalize();
    
    // Set the rotation component
    intermediateToCanvas.makeBasis(canvasXAxis, canvasYAxis, canvasZAxis);
    
    // Apply the translation to position at m
    intermediateToCanvas.setPosition(m);
    
    // Return the two matrices
    return {
      matrix: partToIntermediate,
      matrix2: intermediateToCanvas
    };
  }

export function addToModel(cp, max_id) {

    cp.id = max_id;
    cp.name = "Block" + max_id;

    cp.p1 = p.clone();
    cp.p2 = p2.clone();
    cp.p_dir = p_dir.clone();
    cp.m1 = m.clone();
    cp.m2 = m2.clone();
    cp.m_dir = m_dir.clone();

    state.current =''
    state.current2 =''
    state.currentInModel =''
    state.currentInModel2 =''
    state.step = 0
    state.stepInModel = 0;

    return
    


// Need to setup cp position and rotation
// position of first sensor in part p.x, p.y, p.z
// position of second sensor in part p2.x, p2.y, p2.z
// position of first sensor in model m.x, m.y, m.z
// position of second sensor in model m2.x, m2.y, m2.z

// orientation of first sensor in part p_dir.x, p_dir.y, p_dir.z    
// orientation of second sensor in part p2_dir.x, p2_dir.y, p2_dir.z


//if this is first part place it somehow
if (m.x === 0 && m.y === 0 && m.z === 0 ) {
    // cp.posX = 10;
    // cp.posY = 10;
    // cp.posZ = 10;
    //rotation for first block for test
    // let axis = new THREE.Vector3(1,0,0);
    // let angle = Math.PI/4;  
    // cp.matrix= new THREE.Matrix4().makeRotationAxis(axis, angle);
    // cp.matrix2= new THREE.Matrix4().makeRotationAxis(axis, 0);

    return;
}




//set part rotation to allign first sensor in part with first sensor in model
console.log('p_dir', p_dir);
console.log('m_dir', m_dir);
console.log('p', p);
console.log('m', m);

const { matrix, matrix2 } = defineTransformationMatrices(p, p2, p_dir, m, m2, m_dir);
    cp.matrix= matrix;
    cp.matrix2= matrix2;

// cp.posX = m.x-pt.x;
// cp.posY = m.y-pt.y;
// cp.posZ = m.z-pt.z;

}
