// src/store/modelStore.js
import { proxy, subscribe } from 'valtio';
import { modelService } from '../services/modelService';
import { derive } from 'valtio/utils'
import { authService } from '../services/authService';
import { state } from '../globals';

const modelStore = proxy({
    // Function to directly set the current part
    setCurrentPart: (part) => {
        modelStore.currentPart = part;
    },


    objects: [],
    currentPart: null, // Keep track of the currently selected part for preview.  null = nothing selected
    model: null, // Keep track of the current *saved* model (name, id)

      addCurrentPart: (lt, wt, col, type, transparency) => {

        let max_id = (modelStore.objects && modelStore.objects.length > 0) ? modelStore.objects[modelStore.objects.length - 1].id + 1 : 0;

        const newCurrentPart = {
            id: max_id,
            name: "Block" + max_id,
            part_type: type,
            length: lt,
            width: wt,
            posX: '0',
            color: col,
            transparent: transparency,
            rows: [],
            posY: '0',
            posZ: '0',
            rotX: '0',
            rotY: '0',
            rotZ: '0'
        };

        let newRow;
        let id = 0;


        for (let gi = 0; gi < lt; gi++) {
            for (let gj = 0; gj < wt; gj++) {
                newRow = { i: gi, j: gj, id: id++ };
                newCurrentPart.rows.push(newRow);
            }
        }

        modelStore.currentPart = newCurrentPart;
    },
    // Add a part to the model (from the currentPart)
    addPartToModel: () => {
        if (!modelStore.currentPart) return;

        // Get next ID
        let max_id = (modelStore.objects && modelStore.objects.length > 0)
            ? modelStore.objects[modelStore.objects.length - 1].id + 1
            : 0;

        // Create a copy of current part with updated ID
        const cp = { ...modelStore.currentPart };
        cp.id = max_id;
        cp.name = "Block" + max_id;

        // Add the part to objects array
        modelStore.objects = [...modelStore.objects, cp];
        
        // Reset states after adding to model
        // modelStore.currentPart = null;
        // state.current = null;
        // state.current2 = null;
    },
    
    // Remove a part from the model
    detachPart: (currentb) => {

        modelStore.objects = modelStore.objects.filter((obj) => obj.name !== currentb);
    },

     updateObjectPosition: (objectId, x, y, z) => {
        const objectToUpdate = modelStore.objects.find(obj => obj.id === objectId);
        if (objectToUpdate) {
            objectToUpdate.posX = x;
            objectToUpdate.posY = y;
            objectToUpdate.posZ = z;
        }
    },
    // Save the current model
    saveModel: async (modelName) => {
        const {username} = authService.authState;

        if (!username) {
            console.warn("User not logged in. Cannot save.");
            return;
        }

        if (!modelStore.objects.length) {
            console.warn("No objects to save.");
            return;
        }

        try {
            const savedModel = await modelService.saveModel(modelStore.objects, username, modelName);
            modelStore.model = savedModel;  // Update the current model
        } catch (error) {
            console.error('Error saving model:', error);
        }

    },

    // Load a model by ID
    loadModel: async (modelId) => {
        const {username} = authService.authState;
        if (!username) {
            console.warn("User not logged in. Cannot load.");
            return;
        }

        try {
            // Clean existing state before loading
            modelStore.cleanModel();

            const loadedObjects = await modelService.loadModel(username, modelId);
            modelStore.objects = loadedObjects;

            // Find and update model data
            const modelData = modelStore.availableModels?.find(m => m.model_id === modelId);
            if (modelData) {
                modelStore.model = modelData;
            }
        } catch (error) {
            console.error('Error loading model:', error);
            modelStore.cleanModel(); // Reset state on error
        }

    },
    // Clean the entire model
    cleanModel: () => {
        // Reset all model-related state
        modelStore.objects = [];
        modelStore.model = null;
        modelStore.currentPart = null;
        
        // Reset selection state
        state.current = null;
        state.current2 = null;
        state.currentb = null;
    },
    loadInitialModel: () => {
        // Implement logic to load a default model on startup, if needed.
        // For example, if you have a default model ID, you can call loadModel(defaultModelId) here.
    },

});



// Subscribe to changes in the objects array
subscribe(modelStore, () => {
  // Any logic you want to run when the model changes
  console.log("Model has changed:", modelStore.objects);
});

export { modelStore };
